<template>
    <div class="main">
        <div class="head">
        </div>
        <div class="title-container d-flex flex-column">
            <h1 class="title">Le Groupe</h1>
        </div>
        <div class="history d-flex flex-row">
            <img src="https://api.n-pack.fr/wp-content/uploads/2020/03/IMG_0104-scaled.jpg" id="history-img"
                 alt="Hugues Duhamel"/>
            <div class="d-flex flex-column txt-history">
                <h2>NOTRE HISTOIRE</h2>
                <p>« L’histoire du groupe démarre en 1965, lorsque mon père, Philippe, crée Duhamel Transport. Il ouvre
                    son entrepôt juste à côté de chez nous. Les quais, les palettes et les camions deviennent alors mon
                    terrain de jeu favori.
                    C’est alors naturellement que je m’oriente vers des études de logistique.
                    En 1997, je me lance dans l’entreprenariat et crée Duhamel Logistique, en me basant sur une idée,
                    qui reste aujourd’hui l’ADN du groupe : être un acteur logistique de proximité fondé sur 3 piliers :
                    la Simplicité, la Rapidité et l’Efficacité.<br>
                    <br>
                    D’abord tourné vers la logistique industrielle, c’est en 2006 que commence l’aventure dans le monde
                    passionnant de la logistique de produits de luxe.
                    En quête de nouveaux défis, en 2009, nous nous lançons dans l’insatiable tourbillon de l’e-commerce.<br>
                    <br>
                    Propulsé par la fougue et la motivation de mes collaborateurs, petit à petit la famille s’agrandit.
                    En 2019, nous créons 2 nouvelles filiales, une première spécialisée dans le façonnage et le
                    conditionnement, et une deuxième spécialisée dans la logistique.<br>
                    <br>
                    Début 2020, c’est une nouvelle entité spécialisée dans le développement et la maintenance
                    informatique qui a vu le jour.
                    L’objectif ? Accompagner nos clients, et assurer la gestion de leurs marchandises via un réseau
                    informatique performant, et à notre image.
                    <br>
                    Dans le but de satisfaire toujours plus nos clients, nous nous efforçons à leur fournir des
                    prestations de qualité, tout en respectant l’ensemble de leurs processus.
                    Notre défi quotidien est de nous adapter à leurs besoins en proposant une offre globale répondant à
                    leurs objectifs en matière de logistique, de conditionnement et de transport.<br>
                    <br>
                    Lorsque les moyens de communication actuels tendent à déshumaniser les contacts, il est d’autant
                    plus important de nous appuyer sur notre valeur racine : la proximité.
                    Aujourd’hui président de 5 filiales, je m’efforce chaque jour à cultiver l’esprit familial qui nous
                    anime depuis plus de 50 années au sein du groupe.<br>
                    <br>
                    Les hommes et les femmes de Bamboo accompagnent, au quotidien, leurs clients dans l’optimisation de
                    leur stratégie logistique.
                    À travers ce site, nous souhaitons vous faire découvrir nos coulisses, notre histoire, nos
                    évolutions et nos savoir-faire, mais aussi nos nombreux projets d’avenir. »
                    <br><br>
                    Bonne visite !
                    <br><br>
                    Hugues DUHAMEL<br>
                    Président</p>
            </div>
        </div>
        <div class="bamboo">
            <h3>LE GROUPE BAMBOO EN BREF </h3>
            <p>
                Bamboo est un groupe regroupant différentes filiales: <a href="https://www.duhamel-logistique.fr/"
                                                                         target="_blank">Duhamel Logistique</a>, <a
                    href="https://www.duhamel-transports.fr/" target="_blank">Duhamel Transit International</a>, <a
                    href="https://www.n-pack.fr/" target="_blank">N'Pack</a>,
                N’log et <a href="https://www.n-ine.fr/" target="_blank">N'ine</a>.
                La plupart de ces filiales sont concordantes puisqu’elles se succèdent et respectent l’organisation de
                la chaîne logistique.
                Duhamel Logistique et N’log apportent à leurs clients un service de gestion de stocks, de petit
                conditionnement, de préparation de commandes et de gestion des matières dangereuses.
                Duhamel Transit International propose des solutions de transport en France et vers l’Europe. Elle développe
                également de la prestation de transport (achat et vente de service de transport).
                N’Pack est une société de thermoformage et conditionnement à façon offrant une large gamme de solutions
                techniques et technologiques d’emballages.
                N’ine est une société spécialisée dans l’informatique, elle propose des prestations de développement,
                d’intégration et de support pour les clients des différentes filiales.
                Le groupe Bamboo assure l’optimisation de votre chaîne logistique. De la préparation à la livraison,
                nous
                vous proposons des solutions clés en main pour répondre à vos besoins dans les domaines du BtoB et du
                BtoC.
            </p>
        </div>
        <div class="bandeau">
        </div>
        <div class="organigramme">
            <img src="https://api.n-pack.fr/wp-content/uploads/2021/06/organigramme-Bamboo.png" id="organigramme-img"
                 alt="Organigramme du groupe Bamboo"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Group',
    }
</script>


<style scoped>
    .main {
        width: 100%;
        margin-top: 21%;
    }

    .head {
        width: 100%;
        height: 72px;
        background-image: url("https://api.n-pack.fr/wp-content/uploads/2020/03/2018-08-Duhamel-logistique-inauguration-site-9_47-scaled.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 50%;
    }

    .title-container {
        vertical-align: middle;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .title {
        font-size: 28px;
        font-family: 'Playfair Display';
    }

    .title::before, .title::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 50px;
        height: 1px;
        margin: 0 10px;
        background-color: #4d4e4f;
    }

    .history {
        background-color: #F6F6F6;
        padding-top: 15px;
    }

    #history-img {
        height: 155px;
        margin-left: 15px;
        margin-right: 15px;
    }

    .txt-history {
        text-align: left;
        margin-right: 15px;
        margin-bottom: 15px;
    }

    h2, h3 {
        font-size: 18px;
        font-weight: 900;
    }

    p {
        font-size: 14px;
        text-align: justify;
    }

    a {
        color: #EA670C;
    }

    .bamboo {
        margin: 15px;
        text-align: left;
    }

    .bandeau {
        width: 100%;
        height: 106px;
        background-image: url("https://api.n-pack.fr/wp-content/uploads/2020/03/201511-Duhamel-Logistique_89-scaled.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 62%;
    }

    .organigramme img {
        width: 100%;
    }

    @media (min-width: 576px) {
        .main {
            width: 100%;
            margin-top: 16%;
        }

        .head {
            height: 92px;
        }

        .title-container {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        #history-img {
            height: 215px;
            margin-left: 20px;
            margin-right: 20px;
        }

        .txt-history {
            margin-right: 20px;
            margin-bottom: 20px;
        }

        .bamboo {
            margin: 20px;
        }

        .bandeau {
            height: 126px;
        }
    }

    @media (min-width: 768px) {
        .main {
            width: 100%;
            margin-top: 9%;
        }

        .head {
            height: 112px;
        }

        .title-container {
            margin-top: 40px;
            margin-bottom: 40px;
        }

        .title {
            font-size: 30px;
        }

        .title::before, .title::after {
            width: 60px;
        }

        #history-img {
            height: 305px;
            margin-left: 30px;
            margin-right: 30px;
        }

        .txt-history {
            margin-right: 30px;
            margin-bottom: 30px;
        }

        .bamboo {
            margin: 30px;
        }

        .bandeau {
            height: 146px;
        }
    }

    @media (min-width: 992px) {
        .main {
            width: 100%;
            margin-top: 8%;
        }

        .head {
            height: 132px;
        }

        .title-container {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        #history-img {
            height: 385px;
            margin-left: 100px;
            margin-right: 40px;
        }

        .txt-history {
            margin-right: 40px;
            margin-bottom: 40px;
        }

        .bamboo {
            margin: 40px 60px;
        }

        .bandeau {
            height: 166px;
        }

        #organigramme-img {
            width: 90%;
        }
    }

    @media (min-width: 1200px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 215px;
        }

        .title {
            font-size: 34px;
        }

        .title::before, .title::after {
            width: 70px;
        }

        #history-img {
            height: 505px;
            margin-left: 350px;
            margin-right: 50px;
        }

        .txt-history {
            margin-right: 350px;
            margin-bottom: 50px;
        }

        .bamboo {
            margin: 50px 300px;
        }

        #organigramme-img {
            width: 80%;
        }

        .bandeau {
            height: 266px;
        }

    }

    @media (min-width: 1600px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 252px;
        }

        #history-img {
            height: 541px;
            margin-left: 300px;
            margin-top: 35px;
            margin-right: 50px;
        }

        .txt-history {
            margin-right: 200px;
            margin-bottom: 50px;
            text-align: justify;
        }

        .bamboo {
            margin: 50px 400px;
        }

        #organigramme-img {
            width: 70%;
        }

        .bandeau {
            height: 370px;
        }
    }

</style>
