import Vue from 'vue';
import VueRouter from "vue-router";
import App from './App';
import Group from "./components/Pages/a_propos/Group";
import Main from "./components/Main";
import Entreprise from "./components/Pages/a_propos/Entreprise";
import Collection from "./components/Pages/a_propos/Collection";
import Client from "./components/Pages/a_propos/Client";
import Conditionnement from "./components/Pages/Nos_solutions/Conditionnement";
import Conception from "./components/Pages/Nos_solutions/Conception";
import Thermoformage from "./components/Pages/Nos_solutions/Thermoformage";
import Vision from "./components/Pages/RH/Vision";
import Metiers from "./components/Pages/RH/Metiers";
import Join from "./components/Pages/RH/Join";
import SingleJob from "./components/Pages/RH/SingleJob";
import SingleOffer from "./components/Pages/RH/SingleOffer";
import Recrutement from "./components/Recrutement";
import Spontanee from "./components/Spontanee";
import Newsletters from "./components/Pages/Actualites/Newsletters";
import SingleNewsletter from "./components/Pages/Actualites/SingleNewsletter";
import OnParleDeNous from "./components/Pages/Actualites/OnParleDeNous";
import SingleArticle from "./components/Pages/Actualites/SingleArticle";
import Plan from "./components/Plan";
import Mentions from "./components/Mentions";
import FindUs from "./components/FindUs";

Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history',
    routes: [
        {
          path: '*',
          component: App
        },
        {
            path: '/',
            component: Main
        },
        {
            path: '/le-groupe',
            component: Group
        },
        {
            path: '/l-entreprise',
            component: Entreprise
        },
        {
            path: '/notre-collection',
            component: Collection
        },
        {
            path: '/nos-clients',
            component: Client
        },
        {
            path: '/conditionnement',
            component: Conditionnement
        },
        {
            path: '/conception&fullservice',
            component: Conception
        },
        {
            path: '/thermoformage',
            component: Thermoformage
        },
        {
            path: '/notre-vision',
            component: Vision
        },
        {
            path: '/nos-metiers',
            component: Metiers
        },
        {
            path: '/nous-rejoindre',
            component: Join
        },
        {
            path: '/details/:id',
            component: SingleJob
        },
        {
            path: '/offre/:id',
            component: SingleOffer
        },
        {
            path: '/recrutement/:id',
            component: Recrutement
        },
        {
            path: '/candidature-spontanee',
            component: Spontanee
        },
        {
            path: '/newsletter/:id',
            component: SingleNewsletter
        },
        {
            path: '/newsletters',
            component: Newsletters
        },
        {
            path: '/on-parle-de-nous',
            component: OnParleDeNous
        },
        {
            path: '/on-parle-de-nous/:id',
            component: SingleArticle
        },
        {
            path: '/plan-du-site',
            component: Plan
        },
        {
            path: '/mentions-legales',
            component: Mentions
        },
        {
            path: '/nous-trouver',
            component: FindUs
        },

    ],
    scrollBehavior () {
        return {x: 0, y: 0}
    }
});