<template>
    <div class="Client-post d-flex flex-row">
        <div v-for="client in clients" :key="client.id" class="d-flex flex-row">
            <div class="media-content d-flex flex-column">
                <img class="client-img" :src="client.source_url">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "WpClient",
        data() {
            return {
                clients: []
            }
        },
        created: function () {
            this.$http.get('wp/v2/media?categories=5&per_page=100').then(response => {
                for (let client in response.data) {
                    this.clients.push(response.data[client]);
                }
            }, error => {
                alert(error)
            });
        }
    }
</script>

<style scoped>
    .Client-post {
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
    }

    .media-content {
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 15px;
        justify-content: center;
        width: 40vw;
    }

    .client-img {
        width: 100%;
    }

    @media (min-width: 576px) {
        .media-content {
            margin-bottom: 20px;
        }
    }

    @media (min-width: 768px) {
        .media-content {
            margin-left: 20px;
            margin-right: 20px;
            margin-bottom: 20px;
            width: 28vw;
        }
    }

    @media (min-width: 992px) {
        .media-content {
            margin-left: 30px;
            margin-right: 30px;
            margin-bottom: 30px;
            width: 18vw;
        }
    }

</style>