<template>
    <div class="main">
        <div class="head">
        </div>
        <div class="title-container d-flex flex-column">
            <h1 class="title">Conditionnement</h1>
        </div>
        <div class="txt-head">
            <h2>SUBLIMER LE PRODUIT</h2>
            <p>Valoriser la marque, personnaliser, promouvoir, protéger, assurer la traçabilité du produit, telle est
                notre expertise dans un environnement cosmétique, parapharmaceutique et alimentaire.<br>
                Notre savoir-faire, le co-packing, s’étend de la conception des emballages à la prestation de
                conditionnement en associant le management de la supply chain.</p>
        </div>
        <div class="bandeau">
        </div>
        <div class="d-flex flex-row conditionnement">
            <div id="carre-blanc">
                <div id="carre-noir">
                    <p id="number">1</p>
                </div>
            </div>
            <div class="conditionnement-img">
                <img src="https://api.n-pack.fr/wp-content/uploads/2020/03/1.-Manchonnage.jpg" alt=""/>
            </div>
            <div class="d-flex flex-column conditionnement_txt">
                <h2>Le Manchonnage ou Sleeve</h2>
                <p>
                    Il consiste à valoriser un ou plusieurs produits en communiquant sur l’intégralité de sa surface
                    grâce à un manchon imprimé ou de regrouper au moyen d’un manchon neutre. Le film peut assurer la
                    fonction d’habillage, de protection et de sécurité du produit.
                    <br><br>
                    Le manchonnage de décor se distingue par sa communication à 360° autour du produit, sublimant
                    l’emballage primaire. Pour réaliser ces opérations, les technologies vapeur ou air chaud sont
                    utilisées en fonction de la nature du produit. Des opérations auxquelles peuvent s’ajouter des
                    supports de traçabilité et d’identification.
                </p>
            </div>
        </div>
        <div class="bandeau">
        </div>
        <div class="d-flex flex-row conditionnement">
            <div id="carre-blanc">
                <div id="carre-noir">
                    <p id="number">2</p>
                </div>
            </div>
            <div class="conditionnement-img">
                <img src="https://api.n-pack.fr/wp-content/uploads/2020/03/2.-Pliage-en-X.jpg" alt=""/>
            </div>
            <div class="d-flex flex-column conditionnement_txt">
                <h2>Pliage en X</h2>
                <p>
                    Il consiste à sur-envelopper de façon automatisée un ou plusieurs produits de type étui. Ce type
                    d’emballage assure la promotion de vos marques grâce à l’utilisation d’un film brillant, souvent
                    imprimé avec ou sans tircel (*). Il peut aussi être utilisé pour protéger vos produits haut de
                    gamme, dans les domaines du cosmétique, du luxe et de l’alimentaire.<br><br>

                    (* Bande d’arrachage)
                </p>
            </div>
        </div>
        <div class="bandeau">
        </div>
        <div class="d-flex flex-row conditionnement">
            <div id="carre-blanc">
                <div id="carre-noir">
                    <p id="number">3</p>
                </div>
            </div>
            <div class="conditionnement-img">
                <img src="https://api.n-pack.fr/wp-content/uploads/2020/03/3.-Stretch-Pack.jpg" alt=""/>
            </div>
            <div class="d-flex flex-column conditionnement_txt">
                <h2>Le Stretch Pak</h2>
                <p>
                    Un procédé unique importé en France par N'Pack en 1996. Il est une alternative au blister
                    Haute Fréquence et Thermique, utilisant un film brillant et transparent, qui assure une visibilité
                    totale du produit. Une cartonnette imprimée offrant une grande surface de communication complète cet
                    emballage.<br>
                    Ecologique et économique, cet emballage peut être suspendu ou posé.
                </p>
            </div>
        </div>
        <div class="bandeau">
        </div>
        <div class="d-flex flex-row conditionnement">
            <div id="carre-blanc">
                <div id="carre-noir">
                    <p id="number">4</p>
                </div>
            </div>
            <div class="conditionnement-img">
                <img src="https://api.n-pack.fr/wp-content/uploads/2020/03/4.-Mise-en-étui-automatique.jpg"
                     alt=""/>
            </div>
            <div class="d-flex flex-column conditionnement_txt">
                <h2>Etuyage</h2>
                <p>De la conception au conditionnement, nous réalisons des coffrets et étuis, véritables écrins pour
                    vos produits. Réalisés de façon automatique ou manuelle et intégrant la fabrication des calages, la
                    mise en box et la traçabilité peuvent compléter cette offre.
                </p>
            </div>
        </div>
        <div class="bandeau">
        </div>
        <div class="d-flex flex-row conditionnement">
            <div id="carre-blanc">
                <div id="carre-noir">
                    <p id="number">5</p>
                </div>
            </div>
            <div class="conditionnement-img">
                <img src="https://api.n-pack.fr/wp-content/uploads/2020/03/5.-Blister.jpg"
                     alt=""/>
            </div>
            <div class="d-flex flex-column conditionnement_txt">
                <h2>Blister</h2>
                <p>C’est l’emballage du linéaire par excellence : protégé par un thermoformage soudé sur un support
                    carton ou plastique, le produit peut-être suspendu grâce à un trou d’accroche.
                    <br><br>
                    Nous fabriquons :<br>
                    - Des blisters « haute fréquences » PVC/PVC, PET/PET<br>
                    - Des blisters thermoscellés<br>
                    - Des blisters tout carton<br>
                </p>
            </div>
        </div>
        <div class="bandeau">
        </div>
        <div class="d-flex flex-row conditionnement">
            <div id="carre-blanc">
                <div id="carre-noir">
                    <p id="number">6</p>
                </div>
            </div>
            <div class="conditionnement-img">
                <img src="https://api.n-pack.fr/wp-content/uploads/2020/03/6.-Mise-sous-film.jpg"
                     alt=""/>
            </div>
            <div class="d-flex flex-column conditionnement_txt">
                <h2>Rétractable</h2>
                <p>Il permet la réalisation de lots promotionnels ou la constitution d’unités logistiques. La
                    technologie de nos équipements permet l’utilisation de films imprimés intégrant les codes à barres,
                    infos produits, textes promotionnels ... Le résultat ainsi obtenu en fait un emballage très
                    qualitatif.
                </p>
            </div>
        </div>
        <div class="bandeau">
        </div>
        <div class="d-flex flex-row conditionnement">
            <div id="carre-blanc">
                <div id="carre-noir">
                    <p id="number">7</p>
                </div>
            </div>
            <div class="conditionnement-img">
                <img src="https://api.n-pack.fr/wp-content/uploads/2020/03/7.-Flow-Pack.jpg"
                     alt=""/>
            </div>
            <div class="d-flex flex-column conditionnement_txt">
                <h2>Flow Pack</h2>
                <p>Cette technologie consiste en la réalisation d’un sachet autour du produit, avec soudures
                    transversales et longitudinales. Souvent réalisé à partir de film imprimé, il est particulièrement
                    utilisé dans
                    les domaines de l’alimentaire et de l’échantillonnage. Un trou d’accrochage peut venir compléter
                    cette offre.
                </p>
            </div>
        </div>
        <div class="bandeau">
        </div>
        <div class="d-flex flex-row conditionnement">
            <div id="carre-blanc">
                <div id="carre-noir">
                    <p id="number">8</p>
                </div>
            </div>
            <div class="conditionnement-img">
                <img src="https://api.n-pack.fr/wp-content/uploads/2020/03/9.-PLV-scaled.jpg"
                     alt=""/>
            </div>
            <div class="d-flex flex-column conditionnement_txt">
                <h2>PLV/BOX</h2>
                <p>N'Pack est un expert en conception, montage et remplissage de boxes, incluant la gestion des
                    composants.
                    <br><br>
                    Pour répondre à la problématique de saisonnalité de cette activité promotionnelle, l’entreprise
                    s’est dotée de plusieurs lignes de remplissages modulables permettant de confectionner jusqu’à 6000
                    PLV par semaine.<br>
                    Cette activité intègre aussi une véritable réflexion sur l’ergonomie des postes de travail.
                </p>
            </div>
        </div>
        <div class="bandeau">
        </div>
        <div class="d-flex flex-row conditionnement">
            <div id="carre-blanc">
                <div id="carre-noir">
                    <p id="number">9</p>
                </div>
            </div>
            <div class="conditionnement-img">
                <img src="https://api.n-pack.fr/wp-content/uploads/2020/03/Etiquetage-scaled.jpg"
                     alt=""/>
            </div>
            <div class="d-flex flex-column conditionnement_txt">
                <h2>Etiquetage</h2>
                <p>Que ce soit en ligne ou en opération spécifique, nous pouvons répondre à vos besoins. De la pose
                    d’une
                    simple étiquette promotionnelle à une étiquette entourante, nos matériels sauront s’adapter à vos
                    exigences.
                </p>
            </div>
        </div>
        <div class="bandeau">
        </div>
        <div class="d-flex flex-row conditionnement">
            <div id="carre-blanc">
                <div id="carre-noir">
                    <p id="number10">10</p>
                </div>
            </div>
            <div class="conditionnement-img">
                <img src="https://api.n-pack.fr/wp-content/uploads/2020/03/8.Conditionnement-manuel.jpg"
                     alt=""/>
            </div>
            <div class="d-flex flex-column conditionnement_txt">
                <h2>Conditionnement manuel</h2>
                <p>Nous réalisons aussi toutes prestations de conditionnement manuel lorsque la technologie ne le permet
                    pas.
                    <br><br>
                    Que ce soit pour des raisons de coût, de complexité ou de réactivité, nous adapterons notre
                    organisation pour gérer au mieux vos demandes particulières.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Conditionnement"
    }
</script>

<style scoped>
    .main {
        width: 100%;
        margin-top: 17%;
    }

    .head {
        width: 100%;
        height: 72px;
        background-image: url("https://api.n-pack.fr/wp-content/uploads/2020/03/unclassedcolor2.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 50%;
    }

    .title-container {
        vertical-align: middle;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .title {
        font-size: 28px;
        font-family: 'Playfair Display';
    }

    .title::before, .title::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 50px;
        height: 1px;
        margin: 0 10px;
        background-color: #4d4e4f;
    }

    h2 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    p {
        font-size: 14px;
        text-align: justify;
    }

    .txt-head {
        background-color: #F6F6F6;
        text-align: center;
        padding: 30px;
    }


    #carre-blanc {
        height: 22px;
        width: 22px;
        background: white;
        -ms-transform: rotate(45deg); /* Internet Explorer */
        -moz-transform: rotate(45deg); /* Firefox */
        -webkit-transform: rotate(45deg); /* Safari et Chrome */
        -o-transform: rotate(45deg); /* Opera */
        position: relative;
        left: 42vw;
        top: -55px;
    }

    #carre-noir {
        height: 15px;
        width: 15px;
        background: black;
        margin-top: 3px;
        margin-left: 3px;
        text-align: center;
    }

    #number {
        text-align: center;
        color: #EA670C;
        font-size: 12px;
        padding-bottom: 8px;
        padding-left: 8px;
        -ms-transform: rotate(-45deg); /* Internet Explorer */
        -moz-transform: rotate(-45deg); /* Firefox */
        -webkit-transform: rotate(-45deg); /* Safari et Chrome */
        -o-transform: rotate(-45deg); /* Opera */
    }

    #number10 {
        text-align: center;
        color: #EA670C;
        font-size: 12px;
        padding-bottom: 5px;
        padding-left: 3px;
        -ms-transform: rotate(-45deg); /* Internet Explorer */
        -moz-transform: rotate(-45deg); /* Firefox */
        -webkit-transform: rotate(-45deg); /* Safari et Chrome */
        -o-transform: rotate(-45deg); /* Opera */
    }

    .conditionnement {
        padding-top: 45px;
        padding-bottom: 15px;
        margin-right: 15px;
        margin-left: 15px;
    }

    .conditionnement-img img {
        width: 35vw;
    }

    .conditionnement_txt {
        text-align: left;
        margin-left: 2vw;
    }

    .bandeau {
        width: 100%;
        height: 106px;
        background-image: url("https://api.n-pack.fr/wp-content/uploads/2020/03/5P9A7434-scaled.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 50%;
    }

    @media (min-width: 576px) {
        .main {
            width: 100%;
            margin-top: 11%;
        }

        .head {
            height: 92px;
        }

        .title-container {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        #carre-blanc {
            height: 32px;
            width: 32px;
            left: 46vw;
            top: -56px;
        }

        #number {
            font-size: 16px;
            padding-bottom: 10px;
            padding-left: 10px;
        }

        .conditionnement {
            padding-top: 45px;
            padding-bottom: 20px;
            margin-right: 20px;
            margin-left: 20px;
        }

        .bandeau {
            height: 126px;
        }
    }

    @media (min-width: 768px) {
        .main {
            width: 100%;
            margin-top: 9%;
        }

        .head {
            height: 112px;
        }

        .title-container {
            margin-top: 40px;
            margin-bottom: 40px;
        }

        .title {
            font-size: 30px;
        }

        .title::before, .title::after {
            width: 60px;
        }

        #carre-blanc {
            height: 30px;
            width: 30px;
            left: 46vw;
            top: -59px;
        }

        #carre-noir {
            height: 20px;
            width: 20px;
            margin-top: 6px;
            margin-left: 6px;
        }

        #number {
            font-size: 18px;
            padding-bottom: 12px;
            padding-left: 12px;
        }

        #number10 {
            font-size: 18px;
            padding-bottom: 5px;
            padding-left: 3px;
        }

        .conditionnement {
            padding-top: 45px;
            padding-bottom: 30px;
            margin-right: 30px;
            margin-left: 30px;
        }

        .bandeau {
            height: 146px;
        }
    }

    @media (min-width: 992px) {
        .main {
            width: 100%;
            margin-top: 7%;
        }

        .head {
            height: 132px;
        }

        .title-container {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        #carre-blanc {
            height: 40px;
            width: 40px;
            left: 46vw;
            top: -60px;
        }

        #carre-noir {
            height: 20px;
            width: 20px;
            margin-top: 6px;
            margin-left: 6px;
        }

        #number {
            font-size: 20px;
            padding-bottom: 20px;
            padding-left: 15px;
        }

        #number10 {
            font-size: 18px;
            padding-bottom: 5px;
            padding-left: 3px;
        }

        .conditionnement {
            padding-top: 45px;
            padding-bottom: 40px;
            margin-right: 40px;
            margin-left: 40px;
        }

        .bandeau {
            height: 166px;
        }
    }

    @media (min-width: 1200px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 215px;
        }

        .title {
            font-size: 34px;
        }

        .title::before, .title::after {
            width: 70px;
        }

        #carre-blanc {
            height: 50px;
            width: 50px;
            left: 46vw;
            top: -65px;
        }

        #carre-noir {
            height: 30px;
            width: 30px;
            margin-top: 6px;
            margin-left: 6px;
        }

        #number {
            font-size: 24px;
            padding-bottom: 25px;
            padding-left: 20px;
        }

        #number10 {
            font-size: 21px;
            padding-bottom: 5px;
            padding-left: 3px;
        }

        .conditionnement {
            padding-top: 45px;
            padding-bottom: 50px;
            margin-right: 50px;
            margin-left: 50px;
        }

        .bandeau {
            height: 266px;
        }

    }

    @media (min-width: 1600px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 252px;
        }

        #carre-blanc {
            height: 60px;
            width: 60px;
            left: 46vw;
            top: -70px;
        }

        #carre-noir {
            height: 40px;
            width: 40px;
            margin-top: 6px;
            margin-left: 6px;
        }

        #number {
            font-size: 36px;
            padding-bottom: 30px;
            padding-left: 25px;
        }

        #number10 {
            font-size: 32px;
            padding-bottom: 10px;
            padding-left: 7px;
        }

        .bandeau {
            height: 370px;
        }
    }
</style>