<template>
  <div>
    <carousel
      :per-page="1"
      :mouse-drag="false"
      speed="3000"
      autoplayTimeout="8000"
      direction="rigth"
      autoplay="true"
      loop="true"
    >
      <slide>
        <img
          class="slide-img lazyload locaux"
          src="https://api.n-pack.fr/wp-content/uploads/2020/03/strategie1e2.jpg"
          alt="Locaux N'pack"
        />
      </slide>
      <slide>
        <img
          class="slide-img lazyload"
          src="https://api.n-pack.fr/wp-content/uploads/2020/02/Manchonnage.png"
          alt="Manchonnage"
        />
      </slide>
      <slide>
        <img
          class="slide-img lazyload"
          src="https://api.n-pack.fr/wp-content/uploads/2020/02/Conditionnement.png"
          alt="Conditionnement"
        />
      </slide>
    </carousel>
    <div id="wrapper" class="d-flex flex-row">
      <div class="info1 d-flex">
        <div class="circle">
          <img class="perfume-icon" src="../assets/perfume.svg" alt="Groupe" />
        </div>
        <div class="info-txt d-flex flex-column">
          <div class="d-flex txt-container">
            <number
              class="nb-entreport"
              :class="{ scaleBig: scaleClass }"
              ref="number2"
              :from="numberFrom1"
              :format="theFormat"
              :to="numberTo1"
              :duration="duration"
              easing="Power4.easeOut"
              @complete="completed"
            />
            <span class="txt-entrepot">CLIENTS</span>
          </div>
        </div>
      </div>
      <div class="info2 d-flex">
        <div class="circle">
          <img class="warehouse-icon" src="../assets/warehouse.svg" />
        </div>
        <div class="info-txt d-flex flex-column">
          <div class="d-flex txt-container">
            <number
              class="nb-surface"
              :class="{ scaleBig: scaleClass }"
              ref="number2"
              :from="numberFrom2"
              :format="theFormat"
              :to="numberTo2"
              :duration="duration"
              easing="Power4.easeOut"
              @complete="completed"
            />
            <span class="txt-surface">M² DE STOCKAGE</span>
          </div>
        </div>
      </div>
      <div class="info3 d-flex">
        <div class="circle">
          <img class="card-icon" src="../assets/card.svg" />
        </div>
        <div class="info-txt d-flex flex-column">
          <div class="d-flex txt-container">
            <number
              class="nb-commande"
              :class="{ scaleBig: scaleClass }"
              ref="number2"
              :from="numberFrom3"
              :format="theFormat"
              :to="numberTo3"
              :duration="duration"
              easing="Power4.easeOut"
              @complete="completed"
            />
            <span class="txt-commandes">ANNÉES D'EXPÉRIENCE</span>
          </div>
        </div>
      </div>
      <div class="info4 d-flex">
        <div class="circle">
          <img class="delivery-icon" src="../assets/delivery.svg" />
        </div>
        <div class="info-txt d-flex flex-column">
          <div class="d-flex txt-container">
            <number
              class="nb-engagement"
              :class="{ scaleBig: scaleClass }"
              ref="number2"
              :from="numberFrom4"
              :format="theFormat"
              :to="numberTo4"
              :duration="duration"
              easing="Power4.easeOut"
              @complete="completed"
            />
            <span class="txt-engagement">LIGNES DE CONDITIONNEMENT</span>
          </div>
        </div>
      </div>
    </div>
    <div class="skills d-flex flex-row">
      <div class="skill-condi">
        <img
          src="https://api.n-pack.fr/wp-content/uploads/2020/02/conditionnement2Color.jpg"
        />
        <router-link class="skill-txt-condi" to="/conditionnement"
          >CONDITIONNEMENT</router-link
        >
      </div>
      <div class="skill-concept">
        <img
          src="https://api.n-pack.fr/wp-content/uploads/2020/04/RISLOISE-CRAYONNE-1.png"
        />
        <router-link class="skill-txt-concept" to="/conception"
          >CONCEPTION & FULL SERVICE</router-link
        >
      </div>
      <div class="skill-thermo">
        <img
          src="https://api.n-pack.fr/wp-content/uploads/2020/02/12.agro_.jpg"
        />
        <router-link class="skill-txt-thermo" to="/thermoformage"
          >THERMOFORMAGE</router-link
        >
      </div>
    </div>
    <div class="text-innovation">
      <p class="text_innovation_txt">
        Florian, Frédérique, Guillaume, Frédéric, Élodie...<br />
        tous à votre service
      </p>
    </div>
    <div class="team d-flex flex-row">
      <div class="team-cart cart-1">
        <div class="team-cart-txt"></div>
      </div>
      <div class="team-cart cart-2">
        <div class="team-cart-txt">
          <p>
            - Frédéric -<br />
            Responsable de site
          </p>
        </div>
      </div>
      <div class="team-cart cart-3">
        <div class="team-cart-txt">
          <p>
            - Romain -<br />
            Directeur Technique
          </p>
        </div>
      </div>
      <div class="team-cart cart-4">
        <div class="team-cart-txt">
          <p>
            - Frédérique -<br />
            Directrice Commerciale
          </p>
        </div>
      </div>
      <div class="team-cart cart-5">
        <div class="team-cart-txt">
          <p><br /></p>
        </div>
      </div>
      <div class="team-cart cart-6">
        <div class="team-cart-txt">
          <p>
            - Florian -<br />
            Directeur général
          </p>
        </div>
      </div>
      <div class="team-cart cart-7">
        <div class="team-cart-txt">
          <p>
            - Elodie -<br />
            Responsable RH
          </p>
        </div>
      </div>
      <div class="team-cart cart-8">
        <div class="team-cart-txt">
          <p>
            - Guillaume -<br />
            Directeur N'ine Informatique
          </p>
        </div>
      </div>
      <div class="team-cart cart-9">
        <div class="team-cart-txt"></div>
      </div>
    </div>
    <hr class="separation" />
    <div class="nos-client d-flex flex-column">
      <h3 class="client-title">Nos clients parlent de nous</h3>
      <WpClientPosts></WpClientPosts>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import WpClientPosts from "./WpClientPosts";

export default {
  components: {
    Carousel,
    Slide,
    WpClientPosts,
  },
  data() {
    return {
      numberFrom1: 0,
      numberTo1: 20,
      duration: 5,
      scaleClass: false,
      numberFrom2: 0,
      numberTo2: 20000,
      numberFrom3: 0,
      numberTo3: 30,
      numberFrom4: 0,
      numberTo4: 20,
    };
  },
  methods: {
    theFormat(number) {
      return Intl.NumberFormat("fr-FR", { maximumSignificantDigits: 3 }).format(
        number
      );
    },
    completed() {
      console.log("Animation ends !");
    },
  },
};
</script>

<style scoped>
.slide-img {
  width: 100%;
}

#wrapper {
  background-color: #191919;
  width: 100%;
  margin-top: -9px;
  flex-wrap: wrap;
  padding-left: 15px;
  padding-right: 15px;
}

.circle {
  margin-top: 17px;
  margin-bottom: 7px;
  border-radius: 100%;
  height: 34px;
  width: 33px;
  background-color: white;
  justify-content: center;
}

.txt-container {
  flex-direction: column;
}

.info1 {
  width: 50%;
}

.info2 {
  width: 50%;
}

.info3 {
  width: 50%;
}

.info4 {
  width: 50%;
}

.info-txt {
  text-align: left;
}

.perfume-icon {
  width: 55%;
  margin-top: 5px;
}

.nb-entreport {
  font-size: 8px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 20px;
  margin-left: 5px;
}

.txt-entrepot {
  font-size: 8px;
  margin-left: 5px;
  color: #ffffff;
}

.warehouse-icon {
  width: 85%;
  margin-top: 9px;
}

.nb-surface {
  font-size: 8px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 20px;
  margin-left: 5px;
}

.txt-surface {
  font-size: 8px;
  margin-left: 5px;
  color: #ffffff;
}

.card-icon {
  width: 70%;
  margin-top: 9px;
}

.nb-commande {
  font-size: 8px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 20px;
  margin-left: 5px;
}

.txt-commandes {
  font-size: 8px;
  margin-left: 5px;
  color: #ffffff;
}

.delivery-icon {
  width: 75%;
  margin-top: 5px;
}

.nb-engagement {
  font-size: 8px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 20px;
  margin-left: 5px;
}

.txt-engagement {
  font-size: 8px;
  margin-left: 5px;
  color: #ffffff;
  flex-wrap: wrap;
}

a:hover {
  text-decoration: none;
}

.skills {
  flex-wrap: wrap;
}

.skill-condi {
  width: 100%;
}

.skill-condi img {
  width: 100%;
}

.skill-concept {
  width: 100%;
}

.skill-concept img {
  width: 100%;
}

.skill-thermo {
  width: 100%;
}

.skill-thermo img {
  width: 100%;
}

.skill-txt-condi {
  position: relative;
  top: -50%;
  font-size: 12px;
  font-weight: bold;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px 30px;
}

.skill-txt-concept {
  position: relative;
  top: -50%;
  font-size: 12px;
  font-weight: bold;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px 30px;
}

.skill-txt-thermo {
  position: relative;
  top: -50%;
  font-size: 12px;
  font-weight: bold;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px 30px;
}

.text-innovation {
  font-size: 24px;
}

.text_innovation_txt {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.team {
  flex-wrap: wrap;
  background-color: #000000;
}

.team-cart {
  width: 50%;
  height: 300px;
  background-color: #000000;
}

.team-cart-txt {
  height: 100px;
  background-color: #525452;
  color: white;
  font-size: 18px;
  padding: 10px;
  margin-top: 200px;
}

.cart-1 {
  background-image: url("");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
}

.cart-2 {
  background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2023/11/Frederic.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
}

.cart-3 {
  background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/04/3.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
}

.cart-4 {
  background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2023/09/Frederique-DL.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
}

.cart-5 {
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
}

.cart-6 {
  background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/04/6.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
}

.cart-7 {
  background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/04/7.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
}

.cart-8 {
  background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/04/8.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
}

.cart-9 {
  background-image: url("");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
}

.separation {
  height: 35px;
  width: 100%;
  background-color: #ea670c;
  margin: 0;
}

.nos-client {
  background-color: #f6f6f6;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 30px;
  text-align: center;
}

.client-title {
  font-size: 24px;
  margin-top: 8px;
  margin-bottom: 17px;
}

@media (min-width: 576px) {
  .nb-entreport {
    font-size: 12px;
    margin-top: 15px;
  }

  .txt-entrepot {
    font-size: 12px;
  }

  .nb-surface {
    font-size: 12px;
    margin-top: 15px;
  }

  .txt-surface {
    font-size: 12px;
  }

  .nb-commande {
    font-size: 12px;
    margin-top: 15px;
  }

  .txt-commandes {
    font-size: 12px;
  }

  .nb-engagement {
    font-size: 12px;
    margin-top: 15px;
  }

  .txt-engagement {
    font-size: 12px;
  }

  .skill-condi {
    width: 33.33%;
  }

  .skill-condi img {
    width: 100%;
  }

  .skill-concept {
    width: 33.33%;
  }

  .skill-concept img {
    width: 100%;
  }

  .skill-thermo {
    width: 33.33%;
  }

  .skill-thermo img {
    width: 100%;
  }

  .skill-txt-condi {
    font-size: 12px;
  }

  .skill-txt-concept {
    font-size: 12px;
    padding: 20px 3px;
  }

  .skill-txt-thermo {
    font-size: 12px;
  }

  .team-cart {
    width: 33.33%;
  }

  .client-title {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  #wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }

  .circle {
    height: 44px;
    width: 43px;
  }

  .nb-entreport {
    font-size: 16px;
    margin-top: 15px;
  }

  .txt-entrepot {
    font-size: 16px;
  }

  .nb-surface {
    font-size: 16px;
    margin-top: 15px;
  }

  .txt-surface {
    font-size: 16px;
  }

  .nb-commande {
    font-size: 16px;
    margin-top: 15px;
  }

  .txt-commandes {
    font-size: 16px;
  }

  .nb-engagement {
    font-size: 16px;
    margin-top: 15px;
  }

  .txt-engagement {
    font-size: 16px;
  }

  .skill-txt-condi {
    top: -55%;
    font-size: 16px;
  }

  .skill-txt-concept {
    top: -55%;
    font-size: 16px;
  }

  .skill-txt-thermo {
    top: -55%;
    font-size: 16px;
  }

  .team-cart {
    width: 33.33%;
    height: 400px;
  }

  .team-cart-txt {
    height: 100px;
    background-color: #525452;
    color: white;
    font-size: 18px;
    padding: 10px;
    margin-top: 300px;
  }
}

@media (min-width: 992px) {
  #wrapper {
    height: 135px;
    justify-content: center;
  }

  .info1 {
    width: 115px;
    margin-right: 20px;
  }

  .info2 {
    width: 175px;
    margin-right: 20px;
  }

  .info3 {
    width: 215px;
    margin-right: 20px;
  }

  .info4 {
    width: 260px;
  }

  .circle {
    height: 54px;
    width: 53px;
    margin-top: 40px;
  }

  .nb-entreport {
    font-size: 36px;
    font-weight: 600;
    margin-top: 25px;
  }

  .txt-entrepot {
    font-size: 13px;
  }

  .warehouse-icon {
    margin-top: 12px;
  }

  .nb-surface {
    font-size: 36px;
    font-weight: 600;
    margin-top: 25px;
  }

  .txt-surface {
    font-size: 13px;
  }

  .card-icon {
    margin-top: 13px;
  }

  .nb-commande {
    font-size: 36px;
    font-weight: 600;
    margin-top: 25px;
  }

  .txt-commandes {
    font-size: 13px;
  }

  .delivery-icon {
    margin-top: 8px;
  }

  .nb-engagement {
    font-size: 36px;
    font-weight: 600;
    margin-top: 25px;
  }

  .txt-engagement {
    font-size: 13px;
  }

  .skill-condi {
    width: 33.33%;
    filter: grayscale(1);
  }

  .skill-condi:hover {
    filter: grayscale(0);
  }

  .skill-condi img {
    width: 100%;
  }

  .skill-concept {
    width: 33.33%;
    filter: grayscale(1);
  }

  .skill-concept:hover {
    filter: grayscale(0);
  }

  .skill-concept img {
    width: 100%;
  }

  .skill-thermo {
    width: 33.33%;
    filter: grayscale(1);
  }

  .skill-thermo:hover {
    filter: grayscale(0);
  }

  .skill-thermo img {
    width: 100%;
  }

  .skill-txt-condi {
    font-size: 20px;
  }

  .skill-txt-concept {
    font-size: 20px;
    padding: 20px 5px;
  }

  .skill-txt-thermo {
    font-size: 20px;
  }

  .team-cart {
    width: 11.11%;
    height: 300px;
  }

  .team-cart {
    transition-property: top;
    -webkit-transition-property: top;
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
    top: 0;
    position: relative;
  }

  .team-cart:hover {
    top: -90px;
  }

  .team-cart-txt {
    height: 90px;
    margin-top: 211px;
    font-size: 12px;
    padding-top: 10px;
  }

  .separation {
    position: relative;
    height: 90px;
  }

  .client-title {
    margin-top: 20px;
    margin-bottom: 27px;
  }
}

@media (min-width: 1200px) {
  .info1 {
    width: 130px;
    margin-right: 100px;
  }

  .info2 {
    width: 190px;
    margin-right: 100px;
  }

  .info3 {
    width: 230px;
    margin-right: 100px;
  }

  .info4 {
    width: 270px;
  }

  .circle {
    height: 64px;
    width: 63px;
    margin-top: 35px;
  }

  .skill-txt-condi {
    font-size: 24px;
  }

  .skill-txt-concept {
    font-size: 24px;
  }

  .skill-txt-thermo {
    font-size: 24px;
  }

  .team-cart {
    height: 315px;
    transition-property: top;
    -webkit-transition-property: top;
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
    top: 0;
    position: relative;
  }

  .team-cart:hover {
    top: -110px;
  }

  .team-cart-txt {
    height: 110px;
    margin-top: 315px;
    font-size: 16px;
    padding-top: 10px;
  }

  .separation {
    position: relative;
    height: 110px;
  }

  .client-title {
    margin-top: 30px;
    margin-bottom: 40px;
  }
}

@media (min-width: 1600px) {
  .circle {
    height: 64px;
    width: 63px;
    margin-top: 35px;
  }

  /*.txt-engagement {*/
  /*    font-size: 16px;*/
  /*    width: 440px;*/
  /*}*/

  .team-cart {
    height: 315px;
    transition-property: top;
    -webkit-transition-property: top;
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
    top: 0;
    position: relative;
  }

  .team-cart:hover {
    top: -90px;
  }

  .team-cart-txt {
    height: 90px;
    margin-top: 315px;
    font-size: 16px;
    padding-top: 10px;
  }

  .separation {
    position: relative;
    height: 90px;
  }

  .client-title {
    margin-top: 30px;
    margin-bottom: 40px;
  }
}
</style>