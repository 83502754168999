<template>
    <nav class="header">
        <div class="menu-button" @click="toggle">
            <span>AFFICHER LE MENU</span>
            <img src="https://api.n-pack.fr/wp-content/uploads/2020/03/menu.png">
        </div>

        <Drawer @close="toggle" align="up" :closeable="true">
            <div v-if="open">
                <div class="menu">
                    <div class="menu-column">
                        <span class="title-menu">A PROPOS</span>
                        <a class="is-tab nav-item" href="https://www.dimotrans-group.com/" target="_blank">Le groupe</a>
                        <hr>
                        <router-link class="is-tab nav-item" to="/l-entreprise">L'entreprise</router-link>
                        <hr>
                        <router-link class="is-tab nav-item" to="/notre-collection">Notre collection</router-link>
                        <hr>
                        <router-link class="is-tab nav-item" to="/nos-clients">Nos clients</router-link>
                        <hr>
                    </div>
                    <div class="menu-column">
                        <span class="title-menu">NOS SOLUTIONS</span>
                        <router-link class="is-tab nav-item" to="/conditionnement">Conditionnement</router-link>
                        <hr>
                        <router-link class="is-tab nav-item" to="/conception&fullservice">Conception & Full Service</router-link>
                        <hr>
                        <router-link class="is-tab nav-item" to="/thermoformage">Thermoformage</router-link>
                        <hr>
                    </div>
                    <div class="menu-column">
                        <span class="title-menu">NOS SERVICES</span>
                        <a class="is-tab nav-item" href="http://www.duhamel-logistique.fr/" target="_blank">Duhamel Logistique</a>
                        <hr>
                        <a class="is-tab nav-item" href="https://www.duhamel-transports.fr/" target="_blank">Duhamel Transit International</a>
                        <hr>
                        <a class="is-tab nav-item" href="https://www.n-ine.fr/" target="_blank">N'ine Informatique</a>
                        <hr>
                    </div>
                    <div class="menu-column">
                        <span class="title-menu">ESPACE RH</span>
                        <router-link class="is-tab nav-item" to="/notre-vision">Notre vision</router-link>
                        <hr>
                        <router-link class="is-tab nav-item" to="/nos-metiers">Nos métiers</router-link>
                        <hr>
                        <router-link class="is-tab nav-item" to="/nous-rejoindre">Nous rejoindre</router-link>
                        <hr>
                    </div>
                    <div class="menu-column">
                        <span class="title-menu">ACTUALITÉS</span>
                        <router-link class="is-tab nav-item" to="/newsletters">Newsletters</router-link>
                        <hr>
                        <router-link class="is-tab nav-item" to="/on-parle-de-nous">On Parle de Nous</router-link>
                        <hr>
                    </div>
                </div>
            </div>
        </Drawer>
        <div class="div_logo">
            <router-link to="/" class="logo">
                <img src="https://api.duhamel-logistique.fr/wp-content/uploads/2023/09/logo-npack-dimoblanc-400x400px.png" id="vwp-logo" alt="N'Pack"/>
            </router-link>
        </div>

    </nav>

</template>

<script>
    import Drawer from "vue-simple-drawer"

    export default {
        name: 'TheHeader',
        data() {
            return {
                open: false,
            }
        },
        components: {
            Drawer
        },
        props: {
            msg: String
        },
        methods: {
            toggle() {
                this.open = !this.open
            },
        },
        watch: {
            $route() {
                this.open = false
            }
        }
    }
</script>

<style scoped>
    .header {
        background-color: #191919;
        position: fixed;
        top: 0;
        height: 80px;
        z-index: 100;
        width: 100%;
    }

    .div_logo {
        text-align: center;
        line-height: 80px;
    }

    a {
        color: #ffffff !important;
    }

    a:hover {
        color: #EA670C !important;
        text-decoration: none;
    }

    .menu-button {
        position: inherit;
        left: 20px;
        top: 25px;
        color: white;
    }

    .menu-button span {
        display: none;
    }

    .menu {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .menu-column {
        display: flex;
        flex-direction: column;
        width: 60%;
        margin-bottom: 30px;
        text-align: center;
    }

    .title-menu {
        color: #ffffff;
        justify-content: center;
        border: 1px solid #ffffff;
        border-radius: 4px;
        padding: 10px 15px;
        margin-bottom: 10px;
        width: 54vw;
    }

    .nav-item {
        padding-top: 3px;
        padding-bottom: 3px;
        width: 100%;
    }

    hr {
        width: 35px;
        border-bottom: 1px solid #ffffff;
        margin-top: 0;
    }

    #vwp-logo {
        width: 15%;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    h3 {
        margin: 40px 0 0;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }

    @media (min-width: 576px) {
        #vwp-logo {
            width: 11%;
        }

        .title-menu {
            width: 57vw;
        }
    }

    @media (min-width: 768px) {

        .header {
            height: 90px;
        }

        .div_logo {
            line-height: 90px;
        }

        #vwp-logo {
            width: 9%;
        }

        .menu-button {
            top: 28px;
        }

        .menu-column {
            margin-top: 30px;
            width: 40%;
        }

        .title-menu {
            width: 38vw;
        }

    }

    @media (min-width: 992px) {
        .menu-button {
            top: 15px;
        }

        .header {
            height: 100px;
        }

        .div_logo {
            line-height: 100px;
        }

        .menu-button span {
            display: block;
            width: 80px;
            font-size: 12px;
        }

        .menu-column {
            width: 20%;
        }

        #vwp-logo {
            width: 7%;
        }
    }

    @media (min-width: 1200px) {
        .menu-button {
            top: 10px;
        }

        .menu-button span {
            font-size: 1rem;
        }

        .title-menu {
            width: 14vw;
            margin-left: 3vw;
        }

        #vwp-logo {
            width: 5%;
        }

    }

    @media (min-width: 1600px) {
        .menu-button {
            top: 10px;
        }

        .menu-button span {
            font-size: 1rem;
        }

    }
</style>
