<template>
    <div class="main">
        <div class="head">
        </div>
        <div class="title-container d-flex flex-column">
            <h1 class="title">Nous trouver</h1>
        </div>
        <!--        <iframe src="https://www.google.com/maps/d/embed?mid=1rwdU6YxDe-MoyKbbXmnSpEpu8aQ" width="100%" height="450"-->
        <!--                frameborder="0" style="border:0" allowfullscreen></iframe>-->
        <div class="site_blanc">
            <h2>ACCÈS AU SITE DE BERNAY</h2>
            <div class="adress d-flex">
                <p class="adress-text">1025 Route de Broglie <br>
                    27300 Bernay <br>
                    Tel. 06.45.96.32.18</p>
                <div class="accès d-flex">
                    <h3>Depuis Paris</h3>
                    <p>Sur l’autoroute A13, utiliser la sortie 14 (Evreux, Lisieux, Bernay), tourner à droite sur la voie
                        puis continuer 450 mètres.
                        Après le péage prendre direction Pont Audemer via la D834 puis au second rond point prendre la
                        D438 en direction de BERNAY. Au rond-point suivant, prendre la 4ème sortie sur Route de Broglie
                        (D6138) et continuer sur 250 mètres.
                    </p>
                    <h3>Depuis Le Havre / Rouen</h3>
                    <p>Sur l’autoroute A13, utiliser la voie de bifurcation vers l’autoroute A28 (Bordeaux, Le Mans,
                        Lisieux, Alençon) puis utiliser la sortie 14 (Evreux, Lisieux, Bernay), tourner à droite sur la
                        voie puis continuer 450 mètres.
                        Après le péage prendre direction Pont Audemer via la D834 puis au second rond point prendre la
                        D438 en direction de BERNAY. Au rond-point suivant, prendre la 4ème sortie sur Route de Broglie
                        (D6138) et continuer sur 250 mètres.
                    </p>
                </div>
            </div>
        </div>
        <div class="site_gris">
            <h2>ACCÈS AU SITE DE ST MARCEL</h2>
            <div class="adress d-flex">
                <p class="adress-text">
                    39 Rue des Près <br>
                    27950 Saint-Marcel <br>
                    <!--                    Tel. 02.32.63.32.20 <br>-->
                    <!--                    Fax 02.32.59.17.77 <br>-->
                </p>
                <div class="accès d-flex">
                    <h3>Depuis Paris</h3>
                    <p>Sur l’autoroute A13, utiliser la sortie 16 (Vernon) puis au rond-point prendre la D181. Au
                        rond-point suivant, prendre la 2ème sortie sur D64e et continuer pendant environ 3 kilomètres.
                        Entrer dans Saint-Marcel et continuer D64 sur 1,1 kilomètre, tourner à gauche sur D527 et
                        continuer sur 500 mètres puis tourner à droite sur Rue des Prés et continuer sur 350 mètres. Au
                        rond-point, prendre la 2ème sortie sur Rue des Prés.</p>
                    <h3>Depuis Le Havre / Rouen</h3>
                    <p>Sur l’autoroute A13, utiliser la sortie 16 (Vernon, Pacy-Sur-Eure) puis au rond-point, faire
                        demi-tour et continuer sur D181. Au rond-point suivant, prendre la 2ème sortie sur D64e et
                        continuer pendant environ 3 kilomètres. Entrer dans Saint-Marcel et continuer D64 sur 1,1
                        kilomètre, tourner à gauche sur D527 et continuer sur 500 mètres puis tourner à droite sur Rue
                        des Prés et continuer sur 350 mètres. Au rond-point, prendre la 2ème sortie sur Rue des
                        Prés.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FindUs"
    }
</script>

<style scoped>
    .main {
        width: 100%;
        margin-top: 15%;
        margin-bottom: 50px;
    }

    .head {
        width: 100%;
        height: 72px;
        background-image: url("https://api.n-pack.fr/wp-content/uploads/2020/03/strategie1e-scaled.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 90%;
    }

    .title-container {
        vertical-align: middle;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .title {
        font-size: 16px;
    }

    .title::before, .title::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 1px;
        margin: 0 10px;
        background-color: #4d4e4f;
    }

    iframe {
        margin-bottom: 30px;
    }

    h2 {
        font-size: 18px;
        font-weight: 900;
        color: #4d4e4f;
        text-transform: uppercase;
    }

    h3 {
        color: #555555;
        font-size: 14px;
        font-weight: bold;
    }

    p {
        font-size: 14px;
        text-align: justify;
    }

    .adress {
        flex-direction: column;
        text-align: left;
        margin: 15px;
    }

    .adress-text {
        width: 30%;
        color: #555555;
        font-size: 14px;
    }

    .accès {
        flex-direction: column;
    }

    .site_blanc {
        width: 100%;
        padding: 30px 0px;
    }

    .site_gris {
        width: 100%;
        background-color: #F6F6F6;
        padding: 30px 0px;
    }

    @media (min-width: 576px) {
        .main {
            width: 100%;
            margin-top: 11%;
        }

        .head {
            height: 92px;
        }

        .title-container {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        .title {
            font-size: 18px;
        }
    }

    @media (min-width: 768px) {
        .main {
            width: 100%;
            margin-top: 9%;
        }

        .head {
            height: 112px;
        }

        .title-container {
            margin-top: 40px;
            margin-bottom: 40px;
        }

        .title {
            font-size: 20px;
        }

        .adress {
            flex-direction: row;
            margin: 30px;
        }

        .adress-text {
            width: 25%;
            margin-right: 0px;
            margin-left: 11%;
        }

        .accès {
            width: 50%;
        }
    }

    @media (min-width: 992px) {
        .main {
            width: 100%;
            margin-top: 7%;
        }

        .head {
            height: 132px;
        }

        .title-container {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        .title {
            font-size: 24px;
        }

        .adress {
            margin: 30px 100px;
        }

    }

    @media (min-width: 1200px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 215px;
        }

        .title {
            font-size: 28px;
        }

        .adress {
            margin: 30px 200px;
        }

    }

    @media (min-width: 1600px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 252px;
        }

        .title {
            font-size: 34px;
        }

        .adress {
            margin: 30px 300px;
        }
    }
</style>