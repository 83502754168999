<template>
    <div class="main">
        <div class="head">
        </div>
        <div class="title-container d-flex flex-column">
            <h1 class="title">Plan du site</h1>
        </div>
        <div class="plan">
            <router-link class="is-tab nav-item" to="/">Accueil</router-link>
            <span class="title_cat">A Propos</span>
            <router-link class="is-tab nav-item sub_cat" to="/le-groupe">le groupe</router-link>
            <router-link class="is-tab nav-item sub_cat" to="/l-entreprise">L'entreprise</router-link>
            <router-link class="is-tab nav-item sub_cat" to="/notre-collection">Notre collection</router-link>
            <router-link class="is-tab nav-item sub_cat" to="/nos-clients">Nos clients</router-link>
            <span class="title_cat">Nos Solutions</span>
            <router-link class="is-tab nav-item sub_cat" to="/conditionnement">Conditionnement</router-link>
            <router-link class="is-tab nav-item sub_cat" to="/conception">Conception & Full service</router-link>
            <router-link class="is-tab nav-item sub_cat" to="/thermoformage">Thermoformage</router-link>
            <span class="title_cat">Nos Solutions</span>
            <router-link class="is-tab nav-item sub_cat" to="/nos-metiers">Nos Métiers</router-link>
            <router-link class="is-tab nav-item sub_cat" to="/nous-rejoindre">Nous Rejoindre</router-link>
        </div>

    </div>
</template>

<script>
    export default {
        name: "Plan"
    }
</script>

<style scoped>
    .main {
        width: 100%;
        margin-top: 15%;
        margin-bottom: 50px;
    }

    .head {
        width: 100%;
        height: 72px;
        background-image: url("https://api.n-pack.fr/wp-content/uploads/2020/03/201511-Duhamel-Logistique_89-scaled.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 66%;
    }

    .title-container {
        vertical-align: middle;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .title {
        font-size: 16px;
    }

    .title::before, .title::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 1px;
        margin: 0 10px;
        background-color: #4d4e4f;
    }

    a {
        color: #191919 !important;
        margin-right: 13px;
        margin-left: 13px;
        margin-bottom: 10px;
    }

    a:hover {
        color: #EA670C !important;
        text-decoration: none;
    }

    .plan {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left: 30%;
        margin-right: 20%;
        font-size: 10px;
    }

    .title_cat {
        color: #191919 !important;
        margin-right: 13px;
        margin-left: 13px;
        margin-bottom: 10px;
    }

    .sub_cat {
        margin-left: 20%;
    }

    @media (min-width: 576px) {
        .main {
            width: 100%;
            margin-top: 11%;
        }

        .head {
            height: 92px;
        }

        .title-container {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        .title {
            font-size: 18px;
        }

        .plan {
            margin-left: 35%;
            margin-right: 20%;
            font-size: 12px;
        }
    }

    @media (min-width: 768px) {
        .main {
            width: 100%;
            margin-top: 9%;
        }

        .head {
            height: 112px;
        }

        .title-container {
            margin-top: 40px;
            margin-bottom: 40px;
        }

        .title {
            font-size: 20px;
        }

        .plan {
            margin-left: 35%;
            margin-right: 20%;
            font-size: 16px;
        }

    }

    @media (min-width: 992px) {
        .main {
            width: 100%;
            margin-top: 7%;
        }

        .head {
            height: 132px;
        }

        .title-container {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        .title {
            font-size: 24px;
        }

        .plan {
            margin-left: 40%;
            margin-right: 35%;
        }
    }

    @media (min-width: 1200px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 215px;
        }

        .title {
            font-size: 28px;
        }
    }

    @media (min-width: 1600px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 252px;
        }

        .title {
            font-size: 34px;
        }

        .plan {
            margin-left: 43%;
            margin-right: 40%;
        }
    }
</style>