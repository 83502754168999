<template>
    <div class="main">
        <div class="head">
        </div>
        <div class="title-container d-flex flex-column">
            <h1 class="title">Thermoformage</h1>
        </div>
        <div class="txt-head">
            <h2>BLISTER, CALAGES, PLATEAUX DE MANUTENTION</h2>
            <p>Notre expertise : concevoir et fabriquer des emballages plastiques thermoformés en PET, PVC, PS et leur
                dérivés complexes, pour tout type d'application : calages, plateaux de manutention, couvercles, boîtes,
                blisters...</p>
        </div>
        <div class="bandeau">
        </div>
        <div class="d-flex flex-row thermoformage">
            <div id="carre-blanc">
                <div id="carre-noir">
                    <p id="number">1</p>
                </div>
            </div>
            <div class="thermoformage-img">
                <img src="https://api.n-pack.fr/wp-content/uploads/2020/02/12.agro_.jpg"
                     alt=""/>
            </div>
            <div class="d-flex flex-column thermoformage_txt">
                <h2>Conception et fabrication d'emballages plastiques</h2>
                <p>N'Pack prend en charge les différentes étapes de la conception d'emballage, depuis le cahier des
                    charges à la présérie industrielle.
                    <br><br>
                    Les phases de prototypage, d'échantillonnage, puis de présérie industrielle dans des délais courts
                    permettent de valider les projets packaging.
                    <br><br>
                    Nous travaillons en ligne des matières jusqu'à 1,8mm d'épaisseur : PVC, PET, PS et leurs dérivés
                    expansés ou complexes.
                </p>
            </div>
        </div>
        <div class="bandeau">
        </div>
        <div class="d-flex flex-row thermoformage">
            <div id="carre-blanc">
                <div id="carre-noir">
                    <p id="number">2</p>
                </div>
            </div>
            <div class="thermoformage-img">
                <img src="https://api.n-pack.fr/wp-content/uploads/2020/02/11.conception.jpg"
                     alt=""/>
            </div>
            <div class="d-flex flex-column thermoformage_txt">
                <h2>Application cosmétique, agro-alimentaire</h2>
                <p>Nous réalisons des pièces thermoformées pour l'industrie, notamment pour l'agro-alimentaire et le
                    cosmétique :
                    <br><br>
                    - Barquettes techniques<br>
                    - Coupelles, couvercles, pots, doubles coques à charnières,...<br>
                    - PLV<br>
                    - Calage parfumerie & coffrets<br>
                    - Cale de présentation et de protection<br>
                    - Plateaux de transport et de manutention<br>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Thermoformage"
    }
</script>

<style scoped>
    .main {
        width: 100%;
        margin-top: 17%;
    }

    .head {
        width: 100%;
        height: 72px;
        background-image: url("https://api.n-pack.fr/wp-content/uploads/2020/03/IMG_0218-scaled.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 70%;
    }

    .title-container {
        vertical-align: middle;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .title {
        font-size: 28px;
        font-family: 'Playfair Display';
    }

    .title::before, .title::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 50px;
        height: 1px;
        margin: 0 10px;
        background-color: #4d4e4f;
    }

    .txt-head {
        background-color: #F6F6F6;
        text-align: center;
        padding: 30px;
    }

    h2 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    p {
        font-size: 14px;
        text-align: justify;
    }

    #carre-blanc {
        height: 22px;
        width: 22px;
        background: white;
        -ms-transform: rotate(45deg); /* Internet Explorer */
        -moz-transform: rotate(45deg); /* Firefox */
        -webkit-transform: rotate(45deg); /* Safari et Chrome */
        -o-transform: rotate(45deg); /* Opera */
        position: relative;
        left: 42vw;
        top: -55px;
    }

    #carre-noir {
        height: 15px;
        width: 15px;
        background: black;
        margin-top: 3px;
        margin-left: 3px;
        text-align: center;
    }

    #number {
        text-align: center;
        color: #EA670C;
        font-size: 12px;
        padding-bottom: 8px;
        padding-left: 8px;
        -ms-transform: rotate(-45deg); /* Internet Explorer */
        -moz-transform: rotate(-45deg); /* Firefox */
        -webkit-transform: rotate(-45deg); /* Safari et Chrome */
        -o-transform: rotate(-45deg); /* Opera */
    }

    .thermoformage {
        padding-top: 45px;
        padding-bottom: 45px;
        margin-right: 15px;
        margin-left: 15px;
    }

    .thermoformage-img img {
        width: 30vw;
    }

    .thermoformage_txt {
        text-align: left;
        margin-left: 15px;
    }

    .bandeau {
        width: 100%;
        height: 106px;
        background-image: url("https://api.n-pack.fr/wp-content/uploads/2020/03/5P9A7434-scaled.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 50%;
    }

    @media (min-width: 576px) {
        .main {
            width: 100%;
            margin-top: 11%;
        }

        .head {
            height: 92px;
        }

        .title-container {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        #carre-blanc {
            height: 32px;
            width: 32px;
            left: 46vw;
            top: -56px;
        }

        #number {
            font-size: 16px;
            padding-bottom: 10px;
            padding-left: 10px;
        }

        .thermoformage {
            padding-top: 45px;
            padding-bottom: 20px;
            margin-right: 20px;
            margin-left: 20px;
        }

        .bandeau {
            height: 126px;
        }
    }

    @media (min-width: 768px) {
        .main {
            width: 100%;
            margin-top: 9%;
        }

        .head {
            height: 112px;
        }

        .title-container {
            margin-top: 40px;
            margin-bottom: 40px;
        }

        .title {
            font-size: 30px;
        }

        .title::before, .title::after {
            width: 60px;
        }

        #carre-blanc {
            height: 30px;
            width: 30px;
            left: 46vw;
            top: -59px;
        }

        #carre-noir {
            height: 20px;
            width: 20px;
            margin-top: 6px;
            margin-left: 6px;
        }

        #number {
            font-size: 18px;
            padding-bottom: 12px;
            padding-left: 12px;
        }

        .thermoformage {
            padding-top: 45px;
            padding-bottom: 30px;
            margin-right: 30px;
            margin-left: 30px;
        }

        .bandeau {
            height: 146px;
        }
    }

    @media (min-width: 992px) {
        .main {
            width: 100%;
            margin-top: 7%;
        }

        .head {
            height: 132px;
        }

        .title-container {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        #carre-blanc {
            height: 40px;
            width: 40px;
            left: 46vw;
            top: -60px;
        }

        #carre-noir {
            height: 20px;
            width: 20px;
            margin-top: 6px;
            margin-left: 6px;
        }

        #number {
            font-size: 20px;
            padding-bottom: 20px;
            padding-left: 15px;
        }

        .thermoformage {
            padding-top: 45px;
            padding-bottom: 40px;
            margin-right: 40px;
            margin-left: 40px;
        }

        .bandeau {
            height: 166px;
        }
    }

    @media (min-width: 1200px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 215px;
        }

        .title {
            font-size: 34px;
        }

        .title::before, .title::after {
            width: 70px;
        }

        #carre-blanc {
            height: 50px;
            width: 50px;
            left: 46vw;
            top: -65px;
        }

        #carre-noir {
            height: 30px;
            width: 30px;
            margin-top: 6px;
            margin-left: 6px;
        }

        #number {
            font-size: 24px;
            padding-bottom: 25px;
            padding-left: 20px;
        }

        .thermoformage {
            padding-top: 45px;
            padding-bottom: 50px;
            margin-right: 50px;
            margin-left: 50px;
        }

        .bandeau {
            height: 266px;
        }

    }

    @media (min-width: 1600px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 252px;
        }

        #carre-blanc {
            height: 60px;
            width: 60px;
            left: 46vw;
            top: -70px;
        }

        #carre-noir {
            height: 40px;
            width: 40px;
            margin-top: 6px;
            margin-left: 6px;
        }

        #number {
            font-size: 36px;
            padding-bottom: 30px;
            padding-left: 25px;
        }

        .bandeau {
            height: 370px;
        }
    }

</style>