<template>
    <div class="footer">
        <img src="https://api.duhamel-logistique.fr/wp-content/uploads/2023/09/logo-npack-dimoblanc-400x400px.png" id="vwp-logo" alt="N'Pack"/>
        <hr>
        <div>
            <router-link class="is-tab nav-item" to="/nous-trouver">Nous trouver</router-link>
            <router-link class="is-tab nav-item" to="/mentions-legales">Mentions légales</router-link>
            <router-link class="is-tab nav-item" to="/plan-du-site">Plan du site</router-link>
        </div>
        <hr>
        <div class="info-bottom">
            <a href="tel:+33232201010">02.32.20.10.10</a>
            <a href="mailto:contact@n-pack.fr">Nous contacter</a>
            <a href="https://www.linkedin.com/company/n-pack/" target="_blank">Linkedin</a>
        </div>
        <p>N'Pack - 1025 Route de Broglie - 27300 Bernay</p>
    </div>
</template>

<script>
    export default {
        name: "TheFooter"
    }
</script>

<style scoped>
    .footer {
        background-color: #191919;
    }

    #vwp-logo {
        width: 10%;
    }

    hr {
        color: white;
        border-top: 1px solid #ffffff;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    a {
        color: #ffffff !important;
        font-size: 10px;
        margin-right: 13px;
        margin-left: 13px;
        margin-bottom: 10px;
    }

    a:hover {
        color: #EA670C !important;
        text-decoration: none;
    }

    .info-bottom {
        margin-bottom: 10px;
    }

    p {
        color: #ffffff;
        font-size: 10px;
        padding-bottom: 20px;
        margin-bottom: 0;
    }

    @media (min-width: 768px) {

        a {
            font-size: 12px;
        }

        p {
            font-size: 12px;
        }

    }

    @media (min-width: 1200px) {
        a {
            font-size: 16px;
        }

        p {
            font-size: 16px;
        }

    }

</style>