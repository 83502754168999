<template>
    <div class="main">
        <div class="head">
        </div>
        <div class="title-container d-flex flex-column">
            <h1 class="title">Conception & Full Service</h1>
        </div>
        <div class="d-flex flex-row conception">
            <div class="conception-img">
                <img src="https://api.n-pack.fr/wp-content/uploads/2020/03/RISLOISE-CRAYONNE.png"
                     alt=""/>
            </div>
            <div class="d-flex flex-column conception_txt">
                <h2>CREATEUR DE VALEUR AJOUTÉE</h2>
                <p>N'Pack est une entreprise créatrice de valeur ajoutée au sein de la supply chain grâce à
                    l’innovation dans la conception des packagings et services associés.
                    <br><br>
                    Les équipes développement de N'Pack interviennent en amont des projets afin d'orienter le
                    projet
                    en terme esthétique, technique, économique et développement durable.
                    <br><br>
                    La force de l'entreprise c'est de proposer la meilleure solution sans être limitée dans sa
                    réalisation,
                    compte tenu du niveau élevé d'équipement et de la maîtrise des différentes techniques de packaging.
                    <br><br>
                    N'Pack, c'est une gamme complète de solutions techniques d'emballages associées à des services
                    annexes : nous vous donnons un accès privilégié à notre réseau de spécialistes en lien avec le
                    packaging de vos produits.
                    <br><br>
                    N'Pack, c'est 2 innovations packaging par trimestre qui lui valent d'être un acteur
                    prépondérant
                    et un référent de la supply chain chez l'ensemble de ses donneurs d'ordre.
                    <br><br>
                    Nous nous positionnons comme interlocuteur unique pour toutes les phases du conditionnement de vos
                    produits :
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Conception"
    }
</script>

<style scoped>
    .main {
        width: 100%;
        margin-top: 17%;
    }

    .head {
        width: 100%;
        height: 72px;
        background-image: url("https://api.n-pack.fr/wp-content/uploads/2020/03/unclassedcolor3.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 50%;
    }

    .title-container {
        vertical-align: middle;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .title {
        font-size: 28px;
        font-family: 'Playfair Display';
    }

    .title::before, .title::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 50px;
        height: 1px;
        margin: 0 10px;
        background-color: #4d4e4f;
    }

    h2 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    p {
        font-size: 14px;
        text-align: justify;
    }

    .conception {
        padding: 45px 15px;
        background-color: #F6F6F6;
    }

    .conception-img img {
        width: 40vw;
    }

    .conception_txt {
        text-align: left;
        margin-left: 2vw;
    }

    #etapes {
        width: 100%;
    }

    @media (min-width: 576px) {
        .main {
            width: 100%;
            margin-top: 11%;
        }

        .head {
            height: 92px;
        }

        .title-container {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        .conception {
            padding: 45px 20px;
        }

    }

    @media (min-width: 768px) {
        .main {
            width: 100%;
            margin-top: 9%;
        }

        .head {
            height: 112px;
        }

        .title-container {
            margin-top: 40px;
            margin-bottom: 40px;
        }

        .title {
            font-size: 30px;
        }

        .title::before, .title::after {
            width: 60px;
        }

        .conception {
            padding: 45px 30px;
        }

    }

    @media (min-width: 992px) {
        .main {
            width: 100%;
            margin-top: 8%;
        }

        .head {
            height: 132px;
        }

        .title-container {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        .conception {
            padding: 50px 40px;
        }

    }

    @media (min-width: 1200px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 215px;
        }

        .title {
            font-size: 34px;
        }

        .title::before, .title::after {
            width: 70px;
        }

        .conception {
            padding: 60px 50px;
        }

    }

    @media (min-width: 1600px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 252px;
        }

    }

</style>