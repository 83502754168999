<template>
    <div class="main">
        <div class="head">
        </div>
        <div class="title-container d-flex flex-column">
            <h1 class="title">Nos métiers</h1>
        </div>
        <div class="collection d-flex flex-row">
            <wp-job></wp-job>
        </div>
    </div>
</template>

<script>
    import WpJob from "../../WpJob";

    export default {
        name: "Metiers",
        components: {
            WpJob,
        },
    }
</script>

<style scoped>
    .main {
        width: 100%;
        margin-top: 15%;
    }

    .head {
        width: 100%;
        height: 72px;
        background-image: url("https://api.n-pack.fr/wp-content/uploads/2020/03/IMG_0206-scaled.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 60%;
    }

    .title-container {
        vertical-align: middle;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .title {
        font-size: 16px;
    }

    .title::before, .title::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 1px;
        margin: 0 10px;
        background-color: #4d4e4f;
    }

    @media (min-width: 576px) {
        .main {
            width: 100%;
            margin-top: 11%;
        }

        .head {
            height: 92px;
        }

        .title-container {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        .title {
            font-size: 18px;
        }
    }

    @media (min-width: 768px) {
        .main {
            width: 100%;
            margin-top: 9%;
        }

        .head {
            height: 112px;
        }

        .title-container {
            margin-top: 40px;
            margin-bottom: 40px;
        }

        .title {
            font-size: 20px;
        }
    }

    @media (min-width: 992px) {
        .main {
            width: 100%;
            margin-top: 7%;
        }

        .head {
            height: 132px;
        }

        .title-container {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        .title {
            font-size: 24px;
        }
    }

    @media (min-width: 1200px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 215px;
        }

        .title {
            font-size: 28px;
        }
    }

    @media (min-width: 1600px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 252px;
        }

        .title {
            font-size: 34px;
        }
    }
</style>