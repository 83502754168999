<template>
    <div id="app">
        <TheHeader/>
        <router-view></router-view>
        <TheFooter/>
    </div>
</template>

<script>
    import TheHeader from './components/TheHeader.vue'
    import TheFooter from "./components/TheFooter";

    export default {
        name: 'App',
        components: {
            TheHeader,
            TheFooter
        },
    }
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 10%;
        width: 100%;
    }

    .swal2-styled.swal2-confirm {
        background-color: #ea670c !important;
    }

    .vue-simple-drawer {
        backdrop-filter: blur(10px);
        background: transparent !important;
    }

    .vue-simple-drawer {
        height: 100%;
    }

    .VueCarousel {
        margin-top: 80px;
    }

    .VueCarousel-pagination {
        margin-top: -70px;
        z-index: 10;
    }

    .wp-block-group {
        width: 45%;
    }

    .gauche {
        width: 100%;
        background-color: white;
        padding: 80px;
    }

    .droite {
        background-color: #F6F6F6;
        padding: 80px;
        margin-bottom: 40px;
        width: 100%;
    }

    .missions {
        font-size: 18px;
        font-weight: 900;
        color: #4d4e4f;
        text-transform: uppercase;
        margin-top: 30px;
        margin-bottom: 20px;
        text-align: left;
    }

    .qualites {
        font-size: 18px;
        font-weight: 900;
        color: #4d4e4f;
        text-transform: uppercase;
        margin-top: 30px;
        margin-bottom: 20px;
        text-align: left;
    }

    .prise_post {
        font-size: 18px;
        font-weight: 900;
        color: #4d4e4f;
        text-transform: uppercase;
        margin-top: 30px;
        margin-bottom: 20px;
        text-align: center;
    }

    .prise_post_txt {
        text-align: center;
    }

    .more_info_btn {
        margin-top: 30px;
        margin-bottom: 20px;
        margin-left: 35%;
    }

    .more_info_btn > a {
        font-size: 10px;
        font-weight: 500;
        border-radius: 8px;
        padding: 10px;
        color: white;
    }

    .article_suite {
        margin-top: 30px;
        margin-bottom: 20px;
        margin-left: 35%;
    }

    .article_suite > a {
        font-size: 10px;
        font-weight: 500;
        border-radius: 8px;
        padding: 10px;
        color: white;
    }

    .wp-block-image {
        width: 100%;
        display: flex;
        float: left;
    }

    .wp-block-image img {
        width: 100%;
    }

    ul {
        text-decoration: none;
        padding-left: 0px;
    }

    li {
        background: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/03/arrow.png") no-repeat left center;
        background-size: 10px;
        padding-left: 40px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-top: 1px solid #ccbfa9;
        list-style: none;
    }

    .images_job {
        width: 100%;
        position: relative;
    }

    @media (min-width: 576px) {
        #app {
            margin-top: 8%;
        }

        li {
            background-size: 12px;
        }

        .more_info_btn {
            margin-left: 40%;
        }

        .article_suite {
            margin-left: 40%;
        }

    }

    @media (min-width: 768px) {
        #app {
            margin-top: 7%;
        }

        li {
            background-size: 16px;
        }

        h2 {
            font-size: 16px;
        }

        .gauche {
            float: left;
            width: 50%;
        }

        .droite {
            margin-left: 50%;
            width: 50%;
        }

        .more_info_btn{
            margin-top: -115px;
            margin-bottom: 90px;
            margin-left: 68%;
        }

        .more_info_btn > a {
            font-size: 12px;
            padding: 12px;
            font-weight: 500;
            border-radius: 8px;
            color: white;
        }

        .article_suite > a {
            font-size: 12px;
            padding: 12px;
            font-weight: 500;
            border-radius: 8px;
            color: white;
        }

        .wp-block-image {
            width: 50%;
        }

        .page1 {
            width: 100%;
        }

        .page2 {
            width: 100%;
        }
    }

    @media (min-width: 992px) {
        #app {
            margin-top: 6%;
        }

        li {
            background-size: 18px;
            padding-left: 30px;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .gauche {
            float: left;
            width: 35%;
            margin-left: 15%;
        }

        .droite {
            margin-left: 50%;
            width: 35%;
            margin-right: 15%;
        }

        .more_info_btn{
            margin-left: 60%;
        }

        .more_info_btn > a {
            font-size: 16px;
            padding: 16px;
            font-weight: 500;
            border-radius: 8px;
            color: white;
        }

        .article_suite > a {
            font-size: 16px;
            padding: 16px;
            font-weight: 500;
            border-radius: 8px;
            color: white;
        }

        .wp-block-image {
            width: 50%;
        }

        .page1 {
            width: 80%;
            margin-left: 10%;
            margin-right: 10%;
        }

        .page2 {
            width: 80%;
            margin-left: 10%;
            margin-right: 10%;
        }
    }

    @media (min-width: 1200px) {
        #app {
            margin-top: 5%;
        }

        .page1 {
            width: 60%;
            margin-left: 20%;
            margin-right: 20%;
        }

        .page2 {
            width: 60%;
            margin-left: 20%;
            margin-right: 20%;
        }
    }

    @media (min-width: 1600px) {
        #app {
            margin-top: 4%;
        }

        .page1 {
            width: 40%;
            margin-left: 30%;
            margin-right: 30%;
        }

        .page2 {
            width: 40%;
            margin-left: 30%;
            margin-right: 30%;
        }
    }
</style>
