<template>
    <div class="main">
        <div class="head">
        </div>
        <div class="title-container d-flex flex-column">
            <h1 class="title">Newsletters</h1>
        </div>
        <div class="news">
            <div v-for="newsletter in newsletters" :key="newsletter.id" class="d-flex flex-row">
                <div class="news-content d-flex flex-row">
                    <div class="news-img">
                        <img :src="newsletter.better_featured_image.source_url">
                    </div>
                    <div class="news_content_txt d-flex flex-column">
                        <h2>{{newsletter.title.rendered}}</h2>
                        <p v-html="newsletter.excerpt.rendered"></p>
                        <router-link class="is-tab nav-item news_button " :to="'/newsletter/' + newsletter.id"><p>
                            Lire la suite</p></router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: "Newsletters",
        data() {
            return {
                newsletters: []
            }
        },
        created: function () {
            axios.get('https://api.duhamel-logistique.fr/wp-json/wp/v2/posts?categories=7&per_page=100').then(response => {
                for (let newsletter in response.data) {
                    this.newsletters.push(response.data[newsletter]);
                }
            }, error => {
                alert(error)
            });
        }
    }
</script>

<style scoped>
    .main {
        width: 100%;
        margin-top: 21%;
    }

    .head {
        width: 100%;
        height: 72px;
        background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/04/IMG_0134-scaled.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 58%;
    }

    .title-container {
        vertical-align: middle;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .title {
        font-size: 28px;
        font-family: 'Playfair Display';
    }

    .title::before, .title::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 50px;
        height: 1px;
        margin: 0 10px;
        background-color: #4d4e4f;
    }

    .news-content{
        border-bottom: 2px solid #EA670C;
        margin-bottom: 20px;
        padding: 15px;
    }

    .news-img{
        width: 100%;
    }

    .news-img img{
        width: 100%;
    }

    .news_button{

    }

    p {
        font-size: 14px;
        margin-bottom: 0;
        text-align: justify;
    }

    h2 {
        font-size: 18px;
        font-weight: 900;
        color: #4d4e4f;
        text-transform: uppercase;
        margin-bottom: 10px;
        text-align: center;
    }

    @media (min-width: 576px) {
        .main {
            width: 100%;
            margin-top: 16%;
        }

        .head {
            height: 92px;
        }

        .title-container {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        .news-img{
            width: 60%;
        }

    }

    @media (min-width: 768px) {
        .main {
            width: 100%;
            margin-top: 9%;
        }

        .head {
            height: 112px;
        }

        .title-container {
            margin-top: 40px;
            margin-bottom: 40px;
        }

        .title {
            font-size: 30px;
        }

        .title::before, .title::after {
            width: 60px;
        }

        .news-content{
            padding: 20px;
            text-align: left;
        }

        .news-img{
            width: 40%;
            margin-right: 20px;
        }

        h2 {
            text-align: left;
        }

    }

    @media (min-width: 992px) {
        .main {
            width: 100%;
            margin-top: 8%;
        }

        .head {
            height: 132px;
        }

        .title-container {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        .news-content{
            padding: 30px;
            margin-right: 10%;
            margin-left: 10%;
        }

        .news-img{
            width: 30%;
            margin-right: 30px;
        }
    }

    @media (min-width: 1200px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 215px;
        }

        .title {
            font-size: 34px;
        }

        .title::before, .title::after {
            width: 70px;
        }

        .news-content{
            padding: 40px;
        }

        .news-img{
            width: 20%;
            margin-right: 40px;
        }

    }

    @media (min-width: 1600px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 252px;
        }

        .news-content{
            padding: 50px;
        }

        .news-img{
            width: 15%;
            margin-right: 50px;
        }

    }

</style>