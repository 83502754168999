<template>
    <div class="main">
        <div class="head">
        </div>
        <div class="title-container d-flex flex-column col-12">
            <h1 class="title">L'entreprise</h1>
        </div>
        <div class="d-flex flex-row entreprise">
            <div class="entreprise-img">
                <img src="https://api.n-pack.fr/wp-content/uploads/2020/03/strategie1e-scaled.jpg"
                     alt="Facade du bâtiment N'Pack"/>
            </div>
            <div class="d-flex flex-column entreprise_txt">
                <h2>Une entreprise en avance sur son marché</h2>
                <p>
                    L'entreprise s'est dotée d'un formidable outil de travail en termes technique et de faire-valoir,
                    unique dans son métier : un niveau d'équipements élevé tant dans la diversité que dans le nombre et
                    une vitrine innovante et soignée qui correspond aux marchés cibles de l'entreprise et au prestige de
                    sa clientèle avec des marques premiums, à fortes notoriétés.
                    <br><br>
                    Cet outil de travail garantit la sécurité alimentaire des produits client grâce à des équipements
                    frigorifiques modernes et régulés de stockage, quais et ateliers dédiés.
                    <br><br>
                    L’agrément sous douanes permet le traitement de tous les alcools de bouche. La déclaration ICPE nous
                    permet quant à elle de stocker et de conditionner les aérosols.
                </p>
            </div>
        </div>
        <div class="d-flex flex-row evolution">
            <div class="d-flex flex-column evolution_txt">
                <h2>Une entreprise en perpétuelle évolution</h2>
                <p>
                    De mutations en évolutions, l'entreprise a profondément changé son organisation pour s'adapter aux
                    besoins et aux attentes du marché.
                    <br><br>
                    Encore récemment la mise en place de la modulation du temps de travail a permis d’adapter notre
                    organisation aux fluctuations d’activités de nos clients.
                </p>
            </div>
            <div class="evolution-img">
                <img src="https://api.n-pack.fr/wp-content/uploads/2020/05/strategieimage445.png" id="evolution-img"
                     alt="bâtiment N'Pack"/>
            </div>
        </div>
        <div class="bandeau_1">
        </div>
        <div class="d-flex flex-row developpdura">
            <div class="developpdura-img">
                <img src="https://api.n-pack.fr/wp-content/uploads/2020/03/Rectangle-36.png" id="developpdura-img"
                     alt=""/>
            </div>
            <div class="d-flex flex-column developpdura_txt">
                <h2>Une entreprise investie socialement et orientée développement durable</h2>
                <p>Dans le cadre de son implantation sur le site de Bernay, l'entreprise a consacré 10% du budget
                    des travaux d'aménagement du site au bien-être des salariés par la création d'espaces de travail
                    spacieux et lumineux , d'espaces de pause et de restauration ludiques.
                    <br><br>
                    La mise en place d'une politique de gestion des ressources humaines tournée vers la valorisation du
                    travail et le bien-être des salariés s'est concrétisée suite au rachat de N'Pack par le groupe Dimotrans.
                    <br><br>
                    N'Pack fonctionne avec un système QHSE solide qui satisfait les audits clients et qui garantit
                    les exigences qualités.
                    <br><br>
                    Aussi, l'entreprise oriente ses choix et ses décisions vers les enjeux écologiques, que ce soit dans
                    la création des emballages eux-mêmes ou dans tous les aspects environnementaux de son activité :
                    gestion optimisée des énergies, valorisation des déchets, ...
                </p>
            </div>
        </div>
        <div class="d-flex flex-row investissement">
            <div class="d-flex flex-column investissement_txt">
                <h2>Une politique d'investissement soutenue</h2>
                <p>Dotée d'un esprit innovateur favorisant la fidélisation de ses grands comptes clients, l'entreprise
                    investit de façon permanente :
                    <br><br>
                    - En allant toujours plus loin dans les prestations annexes au packaging, comme l'intégration de la
                    gestion des achats de composants en full-service et l'utilisation d'infrastructures informatiques
                    déportés par nos clients pour la gestion de leur logistique via le WMS.
                    <br><br>
                    - En concevant et en investissant dans des outils de travail ergonomiques comme des lignes
                    semi-automatisées de remplissage de boxes.
                    <br><br>
                    - En renouvelant son parc machine garantissant ainsi un niveau de production performant.
                </p>
            </div>
            <div class="investissement-img">
                <img src="https://api.n-pack.fr/wp-content/uploads/2020/03/strategie4-scaled.jpg" id="investissement-img"
                     alt="Machine à thermoformage"/>
            </div>
        </div>
        <div class="bandeau_2">
        </div>
        <div class="d-flex flex-row valeur">
            <div class="valeur-img">
                <img src="https://api.n-pack.fr/wp-content/uploads/2020/03/strategieimage3-scaled.jpeg" id="valeur-img"
                     alt="Facade du bâtiment N'Pack"/>
            </div>
            <div class="d-flex flex-column valeur_txt">
                <h2>Une entreprise qui créée de la valeur ajoutée</h2>
                <p>En perpétuelle innovation sur ses conceptions packagings, N'Pack se positionne comme un acteur
                    majeur et référent de la Supply Chain chez ses donneurs d’ordres.
                    <br><br>
                    N'Pack, c'est une gamme complète de solutions techniques d'emballages et de services
                    associées.
                    <br><br>
                    Un interlocuteur unique accompagne nos clients de la conception à la mise en production.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Entreprise"
    }
</script>

<style scoped>
    .main {
        width: 100%;
        margin-top: 15%;
    }

    .head {
        width: 100%;
        height: 72px;
        background-image: url("https://api.n-pack.fr/wp-content/uploads/2020/03/strategie1e-scaled.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 90%;
    }

    .title-container {
        vertical-align: middle;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .title {
        font-size: 28px;
        font-family: 'Playfair Display';
    }

    .title::before, .title::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 50px;
        height: 1px;
        margin: 0 10px;
        background-color: #4d4e4f;
    }

    h2, h3 {
        font-size: 18px;
        font-weight: 900;
        margin-bottom: 15px;
    }

    p {
        font-size: 14px;
        text-align: justify;
    }

    .entreprise {
        background-color: #F6F6F6;
        padding: 15px;
    }

    .entreprise-img img {
        width: 106px;
    }

    .entreprise_txt {
        text-align: left;
        margin-left: 15px;
    }

    .evolution {
        padding: 15px;
    }

    .evolution-img img {
        width: 137px;
    }

    .evolution_txt {
        text-align: left;
        margin-right: 15px;
    }

    .bandeau_1 {
        width: 100%;
        height: 106px;
        background-image: url("https://api.n-pack.fr/wp-content/uploads/2020/03/IMG_0200-scaled.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 80%;
    }

    .bandeau_2 {
        width: 100%;
        height: 107px;
        background-image: url("https://api.n-pack.fr/wp-content/uploads/2020/03/IMG_0209-scaled.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 85%;
    }

    .developpdura {
        background-color: #F6F6F6;
        padding: 15px;
    }

    .developpdura-img img {
        width: 143px;
    }

    .developpdura_txt {
        text-align: left;
        margin-left: 15px;
    }

    .investissement {
        padding: 15px;
    }

    .investissement-img img {
        width: 160px;
    }

    .investissement_txt {
        text-align: left;
        margin-right: 15px;
    }

    .valeur {
        background-color: #F6F6F6;
        padding: 15px;
    }

    .valeur-img img {
        width: 144px;
    }

    .valeur_txt {
        text-align: left;
        margin-left: 15px;
    }

    .ambition-img img {
        width: 166px;
    }

    @media (min-width: 576px) {
        .main {
            width: 100%;
            margin-top: 11%;
        }

        .head {
            height: 92px;
        }

        .title-container {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        .entreprise {
            padding: 20px;
        }

        .entreprise-img img {
            width: 253px;
        }

        .entreprise_txt {
            margin-left: 20px;
        }

        .evolution {
            padding: 20px;
        }

        .evolution-img img {
            width: 257px;
        }

        .evolution_txt {
            margin-right: 20px;
        }

        .bandeau_1 {
            height: 156px;
        }

        .bandeau_2 {
            height: 157px;
        }

        .developpdura {
            padding: 20px;
        }

        .developpdura-img img {
            width: 223px;
        }

        .developpdura_txt {
            margin-left: 20px;
        }

        .investissement {
            padding: 20px;
        }

        .investissement-img img {
            width: 240px;
        }

        .investissement_txt {
            margin-right: 20px;
        }

        .valeur {
            padding: 20px;
        }

        .valeur-img img {
            width: 224px;
        }

        .valeur_txt {
            margin-left: 20px;
        }

        .ambition-img img {
            width: 246px;
        }

    }

    @media (min-width: 768px) {
        .main {
            width: 100%;
            margin-top: 9%;
        }

        .head {
            height: 112px;
        }

        .title-container {
            margin-top: 40px;
            margin-bottom: 40px;
        }

        .title {
            font-size: 30px;
        }

        .title::before, .title::after {
            width: 60px;
        }

        .entreprise {
            padding: 30px;
        }

        .entreprise-img img {
            width: 423px;
        }

        .entreprise_txt {
            margin-left: 30px;
        }

        .evolution {
            padding: 30px;
        }

        .evolution-img img {
            width: 337px;
        }

        .evolution_txt {
            margin-right: 30px;
        }

        .bandeau_1 {
            height: 236px;
        }

        .bandeau_2 {
            height: 237px;
        }

        .developpdura {
            padding: 30px;
        }

        .developpdura-img img {
            width: 333px;
        }

        .developpdura_txt {
            margin-left: 30px;
        }

        .investissement {
            padding: 30px;
        }

        .investissement-img img {
            width: 320px;
        }

        .investissement_txt {
            margin-right: 30px;
        }

        .valeur {
            padding: 30px;
        }

        .valeur-img img {
            width: 304px;
        }

        .valeur_txt {
            margin-left: 30px;
        }

        .ambition-img img {
            width: 326px;
        }

    }

    @media (min-width: 992px) {
        .main {
            width: 100%;
            margin-top: 7%;
        }

        .head {
            height: 132px;
        }

        .title-container {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        .entreprise {
            padding: 40px;
        }

        .entreprise-img img {
            width: 423px;
        }

        .entreprise_txt {
            margin-left: 40px;
        }

        .evolution {
            padding: 40px;
        }

        .evolution-img img {
            width: 417px;
        }

        .evolution_txt {
            margin-right: 40px;
        }

        .bandeau_1 {
            height: 286px;
        }

        .bandeau_2 {
            height: 287px;
        }

        .developpdura {
            padding: 40px;
        }

        .developpdura-img img {
            width: 413px;
        }

        .developpdura_txt {
            margin-left: 40px;
        }

        .investissement {
            padding: 40px;
        }

        .investissement-img img {
            width: 400px;
        }

        .investissement_txt {
            margin-right: 40px;
        }

        .valeur {
            padding: 40px;
        }

        .valeur-img img {
            width: 384px;
        }

        .valeur_txt {
            margin-left: 40px;
        }

        .ambition-img img {
            width: 406px;
        }

    }

    @media (min-width: 1200px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 215px;
        }

        .title {
            font-size: 34px;
        }

        .title::before, .title::after {
            width: 70px;
        }

        .entreprise {
            padding: 50px;
        }

        .entreprise-img img {
            width: 503px;
        }

        .entreprise_txt {
            margin-left: 50px;
        }

        .evolution {
            padding: 50px;
        }

        .evolution-img img {
            width: 497px;
        }

        .evolution_txt {
            margin-right: 50px;
        }

        .bandeau_1 {
            height: 326px;
        }

        .bandeau_2 {
            height: 327px;
        }

        .developpdura {
            padding: 50px;
        }

        .developpdura-img img {
            width: 493px;
        }

        .developpdura_txt {
            margin-left: 50px;
        }

        .investissement {
            padding: 50px;
        }

        .investissement-img img {
            width: 480px;
        }

        .investissement_txt {
            margin-right: 50px;
        }

        .valeur {
            padding: 50px;
        }

        .valeur-img img {
            width: 464px;
        }

        .valeur_txt {
            margin-left: 50px;
        }

        .ambition-img img {
            width: 486px;
        }

    }

    @media (min-width: 1600px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 252px;
        }

        .entreprise {
            padding: 60px;
        }

        .entreprise-img img {
            width: 583px;
        }

        .entreprise_txt {
            margin-left: 60px;
        }

        .evolution {
            padding: 60px;
        }

        .evolution-img img {
            width: 577px;
        }

        .evolution_txt {
            margin-right: 60px;
        }

        .bandeau_1 {
            height: 376px;
        }

        .bandeau_2 {
            height: 377px;
        }

        .developpdura {
            padding: 60px;
        }

        .developpdura-img img {
            width: 573px;
        }

        .developpdura_txt {
            margin-left: 60px;
        }

        .investissement {
            padding: 60px;
        }

        .investissement-img img {
            width: 560px;
        }

        .investissement_txt {
            margin-right: 60px;
        }

        .valeur {
            padding: 60px;
        }

        .valeur-img img {
            width: 464px;
        }

        .valeur_txt {
            margin-left: 60px;
        }

        .ambition-img img {
            width: 566px;
        }

    }
</style>