<template>
    <div class="Client-post d-flex flex-row flex-wrap">
        <div v-for="post in posts" :key="post.id" class="d-flex flex-row">
            <img class="testimonial" src="https://api.n-pack.fr/wp-content/uploads/2020/03/Testimonial.png">
            <div class="post-content">
                <p v-html="post.content.rendered"></p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "WpClientPosts",
        data() {
            return {
                posts: []
            }
        },
        created: function () {
            this.$http.get('wp/v2/posts?categories=3&per_page=2').then(response => {
                for (let post in response.data) {
                    this.posts.push(response.data[post]);
                }
            }, error => {
                alert(error)
            });
        }
    }
</script>

<style scoped>
    .Client-post {
        justify-content: space-around;
    }

    .testimonial {
        width: 29px;
        height: 29px;
        margin-right: 10px;
    }

    .post-content {
        font-size: 12px;
        text-align: justify;
        width: 100%;
        margin-bottom: 30px;
    }

    @media (min-width: 576px) {
        .testimonial {
            width: 39px;
            height: 39px;
        }

        .post-content {
            font-size: 16px;
        }
    }

    @media (min-width: 768px) {
        .testimonial {
            width: 49px;
            height: 49px;
        }

        .post-content {
            width: 30vw;
        }
    }

    @media (min-width: 992px) {
        .testimonial {
            width: 59px;
            height: 59px;
        }
    }

    @media (min-width: 1200px) {
        .testimonial {
            width: 69px;
            height: 69px;
        }
    }

</style>