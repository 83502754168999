<template>
    <div class="main">
        <div class="head">
        </div>
        <div class="back">
            <router-link class="is-tab nav-item job_title back_button" to="/newsletters"><span>Retour</span>
            </router-link>
        </div>
        <div class="title-container d-flex flex-column">
            <h1 class="title">{{newsletter.title.rendered}}</h1>
        </div>
        <div class="container d-flex flex-column">
            <p v-html="newsletter.content.rendered"></p>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: "SingleNewsletter",
        data() {
            return {
                newsletter: {}
            }
        },
        created: function () {
            axios.get('https://api.duhamel-logistique.fr/wp-json/wp/v2/posts/' + this.$route.params.id).then(response => {
                this.newsletter = response.data
            }, error => {
                alert(error)
            });
        }
    }
</script>

<style scoped>
    .main {
        width: 100%;
        margin-top: 21%;
    }

    .head {
        width: 100%;
        height: 72px;
        background-image: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/04/IMG_0134-scaled.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 58%;
    }

    .title-container {
        vertical-align: middle;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .title {
        font-size: 28px;
        font-family: 'Playfair Display';
    }

    .title::before, .title::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 50px;
        height: 1px;
        margin: 0 10px;
        background-color: #4d4e4f;
    }

    p {
        font-size: 17px;
        text-align: left;
    }

    .container {
        background-color: #F6F6F6;
        margin: 0;
        padding: 0;
        max-width: 100%;
    }

    .back {
        position: absolute;
        font-size: 10px;
        float: left;
        margin-left: 5px;
        margin-top: 10px;
    }

    .back_button {
        background: url("https://api.duhamel-logistique.fr/wp-content/uploads/2020/04/arrow-back.png") no-repeat left center;
        padding-left: 13px;
        color: #EA670C;
    }

    @media (min-width: 576px) {
        .main {
            width: 100%;
            margin-top: 16%;
        }

        .head {
            height: 92px;
        }

        .title-container {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        .back {
            position: absolute;
            font-size: 10px;
            float: left;
            margin-left: 5px;
            margin-top: 10px;
        }

        .back_button {
            padding-left: 17px;
        }
    }

    @media (min-width: 768px) {
        .main {
            width: 100%;
            margin-top: 9%;
        }

        .head {
            height: 112px;
        }

        .title-container {
            margin-top: 40px;
            margin-bottom: 40px;
        }

        .title {
            font-size: 30px;
        }

        .title::before, .title::after {
            width: 60px;
        }

        .back {
            position: absolute;
            font-size: 12px;
            float: left;
            margin-left: 15px;
            margin-top: 20px;
        }

        .back_button {
            padding-left: 20px;
        }

        .container {
            background-color: #F6F6F6;
            margin: 0;
            padding: 0;
            max-width: 100%;
        }
    }

    @media (min-width: 992px) {
        .main {
            width: 100%;
            margin-top: 8%;
        }

        .head {
            height: 132px;
        }

        .title-container {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        .back {
            position: absolute;
            font-size: 16px;
            float: left;
            margin-left: 25px;
            margin-top: 30px;
        }
    }

    @media (min-width: 1200px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 215px;
        }

        .title {
            font-size: 34px;
        }

        .title::before, .title::after {
            width: 70px;
        }

        .back {
            position: absolute;
            font-size: 16px;
            float: left;
            margin-left: 25px;
            margin-top: 30px;
        }

    }

    @media (min-width: 1600px) {
        .main {
            width: 100%;
            margin-top: -1%;
        }

        .head {
            height: 252px;
        }

        .title {
            font-size: 34px;
        }
    }

</style>